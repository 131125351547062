import { FC, memo, useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';

interface ScrollListenerProps {
  onScroll?: (scroll: number) => void;
  direction?: 'horizontal' | 'vertical';
  paused?: boolean;
}

const ScrollListener: FC<ScrollListenerProps> = (props) => {
  const { onScroll, direction = 'vertical', paused = false } = props;
  const { scroll, isReady } = useLocomotiveScroll();

  useEffect(() => {
    if (isReady) {
      paused ? scroll.stop() : scroll.start();

      scroll.on('scroll', (obj: any) => {
        const scroll = direction === 'horizontal' ? obj.scroll.x : obj.scroll.y;
        onScroll && onScroll(scroll);
      });
    }
  }, [scroll, onScroll, direction, isReady, paused]);

  return <span />;
};

export default memo(ScrollListener);
