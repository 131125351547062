import findKey from 'lodash/findKey';
import { useEffect, useState } from 'react';

const RATIOS = { '4/5': 4 / 5, '2/3': 2 / 3, '1/1': 1 / 1 };
type Ratio = keyof typeof RATIOS;

function findClosestRatio(ratio: number) {
  return Object.values(RATIOS).reduce((prev, curr) =>
    Math.abs(curr - ratio) < Math.abs(prev - ratio) ? curr : prev,
  );
}

interface useImageRatioParams {
  loaded: boolean;
  aspectRatio: Ratio;
  ratio: number;
}

const useImageRatio = (path: string, isVideo: boolean): useImageRatioParams => {
  const [loaded, setLoaded] = useState(false);
  const [aspectRatio, setAspectRatio] = useState<Ratio>('1/1');
  const [ratio, setRatio] = useState(1);

  useEffect(() => {
    if (isVideo) setLoaded(true);
  }, [isVideo]);

  useEffect(() => {
    if (loaded || isVideo) return;

    async function onImageLoad() {
      try {
        const image = new Image();
        image.src = path;
        await image.decode();
        const { width: naturalWidth, height: naturalHeight } = image;
        if (!naturalWidth || !naturalHeight) return;
        const originalRatio = naturalWidth / naturalHeight;
        const closestRatio = findClosestRatio(originalRatio);
        const foundRatio = findKey(RATIOS, (r) => r === closestRatio) as Ratio;
        setAspectRatio(foundRatio);
        setRatio(closestRatio);
        setLoaded(true);
      } catch (error) {
        console.log(path, error);
      }
    }
    onImageLoad();
  }, [isVideo, loaded, path]);

  return {
    loaded,
    aspectRatio,
    ratio,
  };
};

export default useImageRatio;
