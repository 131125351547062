import classNames from 'classnames';
import useQuery from 'hooks/useQuery';
import { ChevronDown } from 'icons/ChevronDown';
import { FAQElement } from 'modules/Faq/types/Faq';
import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FaqKeys } from '../../constants/FaqKeys.enum';

interface FaqArticleProps extends HTMLAttributes<HTMLDivElement> {
  faqElement: FAQElement;
  initialOpen?: boolean;
}

export const FaqArticle: FC<FaqArticleProps> = ({
  faqElement,
  initialOpen = false,
  ...props
}) => {
  const [open, setOpen] = useState(initialOpen);
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(null);
  const query = useQuery();

  useEffect(() => {
    if (open)
      scrollRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  }, [open]);

  const handleTitleClick = useCallback(() => {
    query.set(FaqKeys.UrlParam, faqElement?.key);
    history.replace({ search: query.toString() });
    setOpen(!open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqElement?.key, history, open]);

  return (
    <div ref={scrollRef} {...props}>
      <div
        onClick={handleTitleClick}
        className="uppercase text-center py-4 xl:hidden flex items-center"
      >
        <span className="flex-1 px-10 leading-5">{faqElement?.question}</span>
        <ChevronDown
          className={classNames('transition-transform transform', {
            'rotate-180': open,
          })}
        />
      </div>
      <hr />
      <p
        className={classNames(
          'faq-article transition-all',
          open ? 'h-auto my-14 opacity-100' : 'h-0 overflow-y-hidden opacity-0',
        )}
        dangerouslySetInnerHTML={{ __html: faqElement?.answer || '' }}
      />
    </div>
  );
};

export default FaqArticle;
