import classNames from 'classnames';
import { Button } from 'components/Button';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { Close } from 'icons/Close';
import Cross from 'icons/Cross';
import { Preview } from 'modules/Exhibition/components/Preview';
import { PreviewProps } from 'modules/Exhibition/components/Preview/Preview';
import React, { createRef, FC, useCallback, useEffect, useState } from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import { encodeSvg } from 'utils/utils';

export const FullPagePreview: FC<PreviewProps> = ({ className, ...props }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const previewRef = createRef<HTMLDivElement>();
  const isMobile = useCheckMobileScreen();

  const handlePreviewClick = useCallback(() => {
    setFullScreen(true);
  }, []);

  const handleFullPreviewClick = useCallback(() => {
    setFullScreen(false);
  }, []);

  useEffect(() => {
    if (!isMobile && fullScreen && previewRef.current?.firstElementChild) {
      const elem = previewRef.current.firstElementChild as HTMLImageElement;
      const top = elem.naturalHeight / 2;
      const left = elem.naturalWidth / 2;
      previewRef.current?.scrollTo({
        top,
        left,
        behavior: 'smooth',
      });
    }
  }, [fullScreen, previewRef, isMobile]);

  const handlePreviewMouseMove = ({
    pageX,
    movementX,
    pageY,
    movementY,
    currentTarget,
  }: React.MouseEvent<HTMLImageElement | HTMLDivElement, MouseEvent>): void => {
    const x = pageX + movementX;
    const y = pageY + movementY;
    const elem = currentTarget;
    const { top, left } = elem.getBoundingClientRect();
    if (movementY !== 0 || movementX !== 0) {
      previewRef.current?.scroll({
        top: y - top / 2,
        left: x - left / 2,
      });
    }
  };

  return (
    <>
      <Preview
        onClick={handlePreviewClick}
        className={className}
        style={{ cursor: `url("${encodeSvg(<Cross />)}") 13 13, crosshair` }}
        {...props}
      />
      {fullScreen && (
        <div
          className={classNames('fixed inset-0 w-screen z-50 bg-black', {
            'flex-center': isMobile,
          })}
          style={{ overflow: isMobile ? 'auto' : 'hidden' }}
          ref={previewRef}
        >
          <Button
            onClick={() => setFullScreen(false)}
            borderLess
            className="fixed top-0 right-0 z-10"
          >
            <Close color="white" />
          </Button>
          {isMobile ? (
            <div style={{ width: '100%', height: '100%' }}>
              <PinchZoomPan maxScale={5} zoomButtons={false} position="center">
                <img src={props.preview.path} />
              </PinchZoomPan>
            </div>
          ) : (
            <Preview
              onClick={handleFullPreviewClick}
              className={className}
              onMouseMove={handlePreviewMouseMove}
              style={{
                width: isMobile ? 'auto' : '100%',
                maxWidth: isMobile ? 'initial' : '100%',
                height: isMobile ? '100%' : 'fit-content',
                cursor: `url("${encodeSvg(
                  <Close color="white" />,
                )}") 13 13, pointer`,
              }}
              {...props}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FullPagePreview;
