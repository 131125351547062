import get from 'lodash/get';
import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement } from 'react';
import { useLocation, useParams } from 'react-router';
import useItemDetails from '../hooks/useItemDetails';
import { Item } from '../types/Item';

interface ItemDetailsState {
  item?: Item;
}

const withItemDetails =
  <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const { state = {} } = useLocation();
    const { item: initialItem } = state as ItemDetailsState;
    const exhibitionId = get(initialItem, 'exhibitionId');
    const [item, error] = useItemDetails({ id, exhibitionId });

    return item ? (
      <WrappedComponent item={item} {...props} />
    ) : (
      <PendingPage error={error} />
    );
  };

export default withItemDetails;
