import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { Item, ItemRouteParams } from '../types/Item';

/**Fix url with current slug of item (Art piece)
 *
 * @param item - Item (Art piece) object
 * @param redirectPath - Route where should be redirect with current slug
 *
 * @returns item (Art piece) slug
 */
export const useItemSlugInURL = (
  item: Item,
  redirectPath: string,
): string | undefined => {
  const { id, slug } = useParams<ItemRouteParams>();
  const history = useHistory();

  useEffect(() => {
    if (!item) return;
    if (!slug || (item.slug && slug !== item.slug)) {
      return history.replace(
        generatePath(redirectPath, { id, slug: item.slug }),
      );
    }
  }, [item, history, id, redirectPath, slug]);

  return slug;
};
