import { FilePreview } from 'modules/global/types/File';
import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<FileInfoProps>`
  display: flex;
  ${({ center }) =>
    center && 'justify-content: center;' && 'align-items: center;'}
  ${({ inline }) => !inline && 'flex-direction: column;'}
`;

interface FileInfoProps extends HTMLAttributes<HTMLDivElement> {
  file?: FilePreview;
  name?: string;
  author?: string;
  inline?: boolean;
  center?: boolean;
}

export const FileInfo: FC<FileInfoProps> = (props) => {
  const { file, inline = false, center = true, name, author } = props;

  return (
    <Wrapper inline={inline} center={center} {...props}>
      <span className="mr-2">{name || file?.title}</span>
      <span className="font-medium whitespace-nowrap">
        {author || file?.authorName}
      </span>
    </Wrapper>
  );
};

export default FileInfo;
