import { FC } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { CookiesPolicyProvider } from './components/CookiesPolicy';
import * as routes from './constants/routes';
import PreviewModeProvider from './hooks/usePreviewMode';
import AppLayoutThemeProvider from './layout/AppLayout/AppLayoutProvider';
import { AlgorandProvider } from './modules/Algorand/providers/AlgorandProvider';
import FaqModalProvider from './modules/Faq/providers/FaqModalProvider';
import withParentRoute from './modules/global/hoc/withParentRoute';
import './styles/main.scss';

const { ROUTE_LIST, DEFAULT_ROUTE } = routes;

const App: FC = () => {
  return (
    <CookiesPolicyProvider>
      <Router>
        <FaqModalProvider>
          <AlgorandProvider>
            <PreviewModeProvider>
              <AppLayoutThemeProvider>
                <Switch>
                  {ROUTE_LIST.map(({ path, component, parent }) => (
                    <Route
                      key={path}
                      strict
                      path={path}
                      component={withParentRoute(component, parent)}
                    />
                  ))}
                  <Redirect strict path="/" to={DEFAULT_ROUTE} />
                </Switch>
              </AppLayoutThemeProvider>
            </PreviewModeProvider>
          </AlgorandProvider>
        </FaqModalProvider>
      </Router>
    </CookiesPolicyProvider>
  );
};

export default App;
