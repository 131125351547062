import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement } from 'react';
import { useParams } from 'react-router';
import { useCuratorDetails } from '../hooks/useCuratorDetails';
import { Curator } from '../types/Curator';

const withCuratorDetails =
  <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const [curator, , error] = useCuratorDetails({ id: Number(id) });

    useLayoutConfig({
      HeaderProps: {
        fadeIn: true,
      },
    });

    return curator ? (
      <WrappedComponent curator={curator as Curator} {...props} />
    ) : (
      <PendingPage error={error} />
    );
  };

export default withCuratorDetails;
