import { Variants } from 'framer-motion';

const variants: Variants = {
  loading: {
    y: '44vh',
  },
  loaded: {
    y: '0vh',
    transition: {
      ease: [1, 0, 0.6, 1],
      duration: 0.7,
      delay: 2,
    },
  },
};

export default variants;
