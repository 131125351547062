import classNames from 'classnames';
import { FilePreview } from 'modules/global/types/File';
import React, { FC, HTMLAttributes, memo } from 'react';
import { VideoPreview } from '../VideoPreview';
import { VideoPreviewProps } from '../VideoPreview/VideoPreview';

export type PreviewProps = HTMLAttributes<HTMLDivElement> & {
  preview: FilePreview;
  onLoaded?: () => void;
} & Omit<VideoPreviewProps, 'src'>;

export const Preview: FC<PreviewProps> = (props) => {
  const { preview, className, onLoaded } = props;
  const { path, title, isImage, isVideo } = preview;

  const ImageWrapper = (
    <img
      onLoad={onLoaded}
      src={path}
      alt={title}
      className={classNames(
        'max-w-full pointer-events-none select-none transform-gpu',
        className,
      )}
    />
  );

  const VideoWrapper = (
    <div
      className={classNames(
        'video w-full h-full select-none transform-gpu',
        className,
      )}
    >
      <VideoPreview src={path} onReady={onLoaded} {...props} />
    </div>
  );

  return (
    <>
      {isImage && ImageWrapper}
      {isVideo && VideoWrapper}
    </>
  );
};

export default memo(
  Preview,
  (prev, next) => prev.preview.path === next.preview.path,
);
