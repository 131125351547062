import React, { FC, HTMLAttributes } from 'react';

export const ChevronDown: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="1.4em"
    height="0.75em"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M20.9206 1.07958L10.9546 11.0928L1 1.07958"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </svg>
);
