import { useEffect } from 'react';
import { v4 as generateUUID, validate as uuidValidate } from 'uuid';
import useLocalStorage from './useLocalStorage';

const clientIdKey = 'vsco-client-id';

function useUuid(): string {
  const [uuid, setUuid] = useLocalStorage(clientIdKey, generateUUID());
  const validUUID = uuidValidate(uuid) ? uuid : generateUUID();

  useEffect(() => {
    if (validUUID) {
      setUuid(validUUID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validUUID]);

  return validUUID;
}

export default useUuid;
