import classNames from 'classnames';
import { Button } from 'components/Button';
import { Slider } from 'components/Slider';
import { ScrollListener, SmoothScroll } from 'components/SmoothScroll';
import { VscoSpinner } from 'components/VscoSpinner';
import {
  EXHIBITIONS_ROUTE,
  EXHIBITION_GALLERY_ROUTE,
  ITEM_ROUTE,
} from 'constants/routes';
import { motion } from 'framer-motion';
import useCheckAnimations from 'hooks/useCheckAnimations';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { ArrowLeft } from 'icons/ArrowLeft';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import first from 'lodash/first';
import { ExhibitionBar } from 'modules/Exhibition/components/ExhibitionBar';
import { GalleryItem } from 'modules/Exhibition/components/GalleryItem';
import { useExhibitionSlugInURL } from 'modules/Exhibition/hooks/useExhibitionSlugInURL';
import { Exhibition } from 'modules/Exhibition/types/Exhibition';
import { Nullable } from 'modules/global/types/types';
import { Item } from 'modules/Item/types/Item';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, useHistory } from 'react-router';
import './ExhibitionGallery.scss';

const FADE_IN_DELAY = 100;

interface ExhibitionParams {
  exhibition: Exhibition;
}

export const ExhibitionGallery: FC<ExhibitionParams> = ({ exhibition }) => {
  const [selectedItem, setSelectedItem] = useState<Nullable<Item>>(null);
  const [animationEnded, setAnimationEnded] = useState(false);
  const [scroll, setScroll] = useState<number>(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedItems: Item[] = [];
  const [mediaLoaded, setMediaLoaded] = useState<boolean>(false);
  const itemsWithMedia = exhibition.items?.filter(
    (item) => item.preview.isImage,
  );
  const isMobile = useCheckMobileScreen();
  const history = useHistory();
  const animationsSupported = useCheckAnimations();
  useExhibitionSlugInURL(exhibition, EXHIBITION_GALLERY_ROUTE);

  useEffect(() => {
    setScroll(0);
  }, []);

  const goToDetails = useCallback(
    (item: Item) =>
      history.push(generatePath(ITEM_ROUTE, { id: item.id }), { item, scroll }),
    [history, scroll],
  );

  const onItemLoad = useCallback(
    (item: Item) => {
      loadedItems.push(item);
      if (loadedItems.length >= itemsWithMedia.length) {
        setMediaLoaded(true);
      }
    },
    [itemsWithMedia.length, loadedItems],
  );

  const handleGalleryItemClick = useCallback(
    (item: Item) => {
      if (!selectedItem) setSelectedItem(item);
      if (!animationsSupported) setAnimationEnded(true);
    },
    [animationsSupported, selectedItem],
  );

  useEffect(() => {
    if (animationEnded && selectedItem) {
      goToDetails(selectedItem);
    }
  }, [selectedItem, animationEnded, goToDetails]);

  const handleAnimationEnd = useCallback(() => {
    setAnimationEnded(true);
  }, []);

  const onExhibitionsClick = useCallback(
    () => history.push(EXHIBITIONS_ROUTE),
    [history],
  );

  useEffect(() => {
    if (!isMobile) {
      const html = first(document.getElementsByTagName('html'));
      const body = first(document.getElementsByTagName('body'));
      const bouncingClass = 'prevent-bouncing';

      html?.classList.add(bouncingClass);
      body?.classList.add(bouncingClass);

      return () => {
        html?.classList.remove(bouncingClass);
        body?.classList.remove(bouncingClass);
      };
    }
  }, [isMobile]);

  useLayoutConfig({
    className: 'overflow-x-hidden text-xs',
    HeaderProps: {
      fadeOut: selectedItem ? true : false,
      fadeIn: !selectedItem ? true : false,
      withBackButton: false,
      customLeftButton: isMobile ? (
        <Button
          borderLess={true}
          withoutPadding={true}
          onClick={onExhibitionsClick}
        >
          <ArrowLeft />
        </Button>
      ) : (
        <Button
          upperCase={true}
          borderLess={true}
          withoutPadding={true}
          variant="link"
          onClick={onExhibitionsClick}
        >
          All Exhibitions
        </Button>
      ),
      text: <ExhibitionBar exhibition={exhibition} header />,
    },
  });

  const handleMediaLoad = useCallback(
    (item: Item) => {
      onItemLoad(item);
    },
    [onItemLoad],
  );

  const child = {
    loading: {
      opacity: 0,
    },
    loaded: {
      opacity: 1,
    },
  };

  const handleOnScroll = useCallback((offset: number) => {
    setScroll(offset);
  }, []);

  return (
    <div
      className={classNames('exhibition-gallery', {
        'list-animating': animationsSupported && selectedItem,
      })}
      id="exhibition-gallery"
    >
      <Helmet>
        {exhibition.items?.map((item) => (
          <link
            key={item.id}
            rel="preload"
            href={item.preview.path}
            as="image"
          />
        ))}
      </Helmet>
      {!mediaLoaded && (
        <div className="loading">
          <VscoSpinner />
        </div>
      )}
      <SmoothScroll direction="horizontal">
        <ScrollListener
          direction="horizontal"
          paused={Boolean(selectedItem) || !mediaLoaded}
          onScroll={handleOnScroll}
        />
        <Slider disableDrag={Boolean(selectedItem)} loaded={mediaLoaded}>
          {exhibition.items?.map((item, index) => (
            <motion.div
              key={`gallery-item-${item.id}`}
              variants={child}
              initial="loading"
              animate={mediaLoaded ? 'loaded' : 'loading'}
              transition={{
                duration: 0.8,
                ease: [0.3, 0.7, 0.2, 0.7],
                delay: index * 0.15,
              }}
            >
              <GalleryItem
                item={item}
                selectedItem={selectedItem}
                onPress={handleGalleryItemClick}
                onEnd={handleAnimationEnd}
                onMediaLoad={handleMediaLoad}
                scroll={scroll}
                style={{
                  animationDelay: `${
                    index * (selectedItem ? 0 : FADE_IN_DELAY)
                  }ms`,
                }}
              />
            </motion.div>
          ))}
        </Slider>
      </SmoothScroll>
    </div>
  );
};

export default ExhibitionGallery;
