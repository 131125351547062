import { ExhibitionLogo } from 'components/ExhibitionLogo';
import { TERMS_API_PATH } from 'constants/apiPaths';
import useAxios, { AxiosState } from 'hooks/useAxios';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { PendingPage } from 'pages/PendingPage';
import React, { FC } from 'react';
import './TermsPage.scss';

type TermsResponse = {
  id: number;
  description: string;
};

export const TermsPage: FC = () => {
  const { response: terms, isError: error } = useTermsContent();

  useLayoutConfig({
    HeaderProps: {
      withBackButton: true,
      text: <ExhibitionLogo />,
    },
    withFooter: true,
  });

  return terms ? (
    <div className="terms">
      <article
        className="terms-article w-full text-sm p-5 pt-60 md:p-36"
        dangerouslySetInnerHTML={{ __html: terms.description }}
      />
    </div>
  ) : (
    <PendingPage error={error} />
  );
};

const useTermsContent = (): AxiosState<TermsResponse> =>
  useAxios<TermsResponse>(TERMS_API_PATH);

export default TermsPage;
