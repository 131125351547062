import { useEffect } from 'react';
import { AppLayoutProps } from './AppLayout';
import { useAppLayoutTheme } from './AppLayoutProvider';

const useLayoutConfig = (config?: AppLayoutProps): void => {
  const { setAppLayoutProps, cleanUpLayout } = useAppLayoutTheme();

  useEffect(() => {
    setAppLayoutProps(config);
    return () => {
      cleanUpLayout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.HeaderProps?.fadeOut, config?.HeaderProps?.fadeIn]);
};

export default useLayoutConfig;
