import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { ItemDetails } from 'modules/Item/components/ItemDetails';
import { Item } from 'modules/Item/types/Item';
import { Transaction } from 'modules/Transaction/types/Transaction';
import React, { FC } from 'react';

export interface RewardPageProps {
  transaction: Transaction;
}

export const RewardPage: FC<RewardPageProps> = ({ transaction }) => {
  useLayoutConfig({
    HeaderProps: {
      withBackButton: true,
    },
    withFooter: true,
  });

  return (
    <ItemDetails item={transaction.item as Item} transaction={transaction} />
  );
};

export default RewardPage;
