import { AxiosRequestConfig } from 'axios';
import { Reducer, useEffect, useReducer } from 'react';
import api from 'utils/api';
import { getApiErrorMessage } from 'utils/utils';

type AxiosAction =
  | { type: 'AXIOS_INIT' }
  | { type: 'AXIOS_SUCCESS'; payload: any }
  | { type: 'AXIOS_FAILURE'; error: any; code: number };

export interface AxiosState<P = any> {
  isLoading: boolean;
  isError: boolean;
  code?: number;
  response?: P;
}

const axiosReducer: Reducer<AxiosState, AxiosAction> = (state, action) => {
  switch (action.type) {
    case 'AXIOS_INIT':
      return {
        isLoading: true,
        isError: false,
      };
    case 'AXIOS_SUCCESS':
      return {
        isLoading: false,
        isError: false,
        response: action.payload,
      };
    case 'AXIOS_FAILURE':
      return {
        isLoading: false,
        isError: action.error || true,
        errorCode: action.code,
      };
    default:
      throw new Error();
  }
};

const useAxios = <P,>(
  url: string,
  config?: AxiosRequestConfig,
): AxiosState<P> => {
  const [state, dispatch] = useReducer(axiosReducer, {
    isLoading: false,
    isError: false,
  });

  useEffect(() => {
    if (!url) return;
    const callAxios = async () => {
      dispatch({ type: 'AXIOS_INIT' });

      try {
        const response = await api(url, { ...config });
        dispatch({ type: 'AXIOS_SUCCESS', payload: response.data });
      } catch (error: any) {
        dispatch({
          type: 'AXIOS_FAILURE',
          error: getApiErrorMessage(error),
          code: error.response?.status,
        });
      }
    };

    callAxios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return state;
};

export default useAxios;
