import React, { FC, HTMLAttributes } from 'react';

export const AlgorandLogo: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  ...props
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1500 650"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M457.8,233.2v180.5H421V233.2H457.8z" />
      <path
        d="M608,292v122.1c0,37.9-29.5,61.2-65.6,61.2c-34.8,0-60.8-21.6-63.3-52.1l36.7,0.2c1.5,13,12.9,18.6,27.9,18.6
		c14.2,0,27.4-7.6,27.4-27.4V399c-9.4,6.1-21.4,9.7-34.8,9.7c-33.6,0-60.5-27.2-60.5-58.5c0-36.4,27-60.8,60.5-60.8
		c13.5,0,25.4,3.6,34.8,9.7v-6.6L608,292z M571.2,363.9v-29.7c-7.5-10.9-17.9-14.5-28.2-14.5c-18.1,0-30.7,12.1-30.7,30.5
		c0,13.5,12.6,28.3,30.7,28.3C553.3,378.4,563.7,374.7,571.2,363.9z"
      />
      <path
        d="M754.8,351.4c0,37.6-27.8,64.4-65.1,64.4c-37.6,0-65.4-26.8-65.4-64.4c0-37.3,27.8-64.6,65.4-64.6
		C727,286.8,754.8,314.1,754.8,351.4z M719.3,351.4c0-17.7-11.6-32-29.6-32c-18.2,0-29.8,14.3-29.8,32c0,18.2,11.6,31.8,29.8,31.8
		C707.6,383.1,719.3,369.6,719.3,351.4z"
      />
      <path
        d="M983.3,292.2v121.3l-36.1,0.2v-6.9c-9.4,6.1-21.4,9.9-34.8,9.9c-33.6,0-60.5-28.5-60.5-63.6c0-35.3,27-63.8,60.5-63.8
		c13.5,0,25.4,3.8,34.8,9.9v-6.9L983.3,292.2z M947.2,371.7v-37.2c-7.6-10.8-18.2-13.8-28.8-13.8c-18.5,0-31.3,14.8-31.3,32.5
		c0,17.5,12.8,32.3,31.3,32.3C929,385.4,939.6,382.5,947.2,371.7z"
      />
      <path
        d="M1131.7,347.8v65.9h-35.5v-61.6c0-19.8-9.9-30.3-27.5-30.3c-9.2,0-19.3,5.3-28,15.5v76.4h-36.3V292.4h36.3v9.2
		c9.9-7.9,21.4-12.2,35.1-12.2C1108.2,289.4,1131.7,312.2,1131.7,347.8z"
      />
      <g id="lINT7W_1_">
        <g>
          <polygon
            points="404.2,413.6 366.7,413.6 342.3,322.9 289.8,413.6 247.9,413.6 328.9,273.2 315.9,224.4 206.6,413.7 164.7,413.7 
				303.2,173.7 339.9,173.7 356,233.3 393.9,233.3 368,278.3 			"
          />
        </g>
      </g>
      <path
        d="M1278.3,233.2v180.5h-36.8v-6.9c-9.4,6.1-21.4,9.9-34.8,9.9c-33.6,0-60.5-28.5-60.5-63.8c0-35.1,27-63.6,60.5-63.6
		c13.5,0,25.4,3.8,34.8,9.9v-66.1L1278.3,233.2L1278.3,233.2z M1241.5,371.8v-37.5c-7.7-10.9-18.4-13.9-29-13.9
		c-18.6,0-31.5,14.9-31.5,32.5c0,17.9,12.9,32.8,31.5,32.8C1223.1,385.7,1233.8,382.7,1241.5,371.8z"
      />
      <path d="M845.3,323c-8,1-26.8,1.9-37.8,17.1v73.7h-36.3V292.6h36.3v9.1c8.3-7.1,20.8-11.3,37.8-9.6" />
    </g>
  </svg>
);
