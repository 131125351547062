import { useEffect, useState } from 'react';
import { browserVersion, isSafari } from 'react-device-detect';
import useCheckMobileScreen from './useCheckMobileScreen';

const SUPPORTED_SAFARI_VERSION = 14;

const useCheckAnimations = (): boolean => {
  const isMobile = useCheckMobileScreen();
  const [animationsSupported, setAnimationsSupported] = useState(true);
  const unsupportedSafari =
    isSafari && Number(browserVersion) < SUPPORTED_SAFARI_VERSION;

  useEffect(() => {
    if (isMobile || unsupportedSafari) {
      setAnimationsSupported(false);
    }
  }, [isMobile, unsupportedSafari]);

  return animationsSupported;
};

export default useCheckAnimations;
