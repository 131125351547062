import { Variants } from 'framer-motion';

const variants: Variants = {
  initial: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export default variants;
