import { AxiosRequestConfig } from 'axios';
import { CURATORS_API_PATH } from 'constants/apiPaths';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { curatorSerializer } from '../serializers/curatorSerializer';
import { Curator } from '../types/Curator';

export const useCuratorDetails = ({
  id,
}: Pick<Curator, 'id'> & { config?: AxiosRequestConfig }): [
  Curator | undefined,
  boolean,
  boolean,
] => {
  const [curator, setCurator] = useState<Curator>();
  const { response, isLoading, isError } = useAxios<Curator>(
    `${CURATORS_API_PATH}/${id}`,
  );

  useEffect(() => {
    if (response) {
      setCurator(curatorSerializer(response));
    }
  }, [response]);

  return [curator, isLoading, isError];
};
