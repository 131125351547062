import { Button } from 'components/Button';
import { CURATOR_ROUTE, EXHIBITION_ROUTE } from 'constants/routes';
import ArrowRight from 'icons/ArrowRight';
import isEmpty from 'lodash/isEmpty';
import { Curator } from 'modules/Curator/types/Curator';
import { Exhibition } from 'modules/Exhibition/types/Exhibition';
import { useFaqModal } from 'modules/Faq/providers/FaqModalProvider';
import React, { FC, useCallback } from 'react';
import { generatePath, useHistory } from 'react-router';
import './ExhibitionsNavList.scss';

interface ExhibitionsNavListProps {
  header: string;
  exhibitions: Exhibition[];
  curatedBy?: Curator;
  withPreviewTitle?: boolean;
}

export const ExhibitionsNavList: FC<ExhibitionsNavListProps> = ({
  header,
  exhibitions,
  curatedBy,
  withPreviewTitle = false,
}) => {
  const history = useHistory();
  const { close } = useFaqModal();

  const goToExhibition = useCallback(
    (event, id: string | number) => {
      history.push(generatePath(EXHIBITION_ROUTE, { id }));
      close();
      event.stopPropagation();
    },
    [close, history],
  );

  const goToCurator = useCallback(
    (event, id: number) => {
      if (event.target !== event.currentTarget) return;
      history.push(generatePath(CURATOR_ROUTE, { id }));
      close();
      event.stopPropagation();
    },
    [history, close],
  );

  const arrow = (
    <Button rounded withoutPadding className="px-5 mr-5 arrow-button">
      <ArrowRight className="transition-transform" />
    </Button>
  );

  if (isEmpty(exhibitions)) {
    return null;
  }

  return (
    <div className="text-3xl xl:text-4xl my-9">
      <span className="block py-5 border-b">{header}</span>
      {exhibitions?.map((exhibition) => {
        const curator = exhibition.curator || curatedBy;
        const { id, name, title } = exhibition;
        const nameLabel = <span className="uppercase mr-5">{name}</span>;
        const previewTitleLabel = <span className="mr-5">{title}</span>;

        return (
          <div key={`exhibition-${id}`} className="exhibition-row">
            <div className="overflow-x-auto whitespace-nowrap -mx-5 px-5 xl:mx-auto xl:px-0">
              <div
                className="flex items-center py-3 xl:py-5 cursor-pointer"
                onClick={(e) => goToExhibition(e, id)}
              >
                {withPreviewTitle ? (
                  <>
                    {previewTitleLabel}
                    {arrow}
                    {nameLabel}
                  </>
                ) : (
                  <>
                    {nameLabel}
                    {arrow}
                  </>
                )}
                <span className="mr-5">curated by</span>
                <span
                  className="uppercase hover:underline"
                  onClick={(e) => goToCurator(e, curator.id)}
                >
                  {curator.name}
                </span>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default ExhibitionsNavList;
