import classNames from 'classnames';
import useQuery from 'hooks/useQuery';
import noop from 'lodash/noop';
import { FaqPage } from 'pages/FaqPage';
import { FaqKeys } from 'pages/FaqPage/constants/FaqKeys.enum';
import {
  AnimationEvent,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import './FaqModalProvider.scss';

interface FaqModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  open: () => void;
  close: () => void;
}

const FaqModalContext = createContext<FaqModalProps>({
  isOpen: false,
  setIsOpen: noop,
  open: noop,
  close: noop,
});

const FaqModalProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const query = useQuery();
  const history = useHistory();

  const open = useCallback(() => {
    setIsMounted(true);
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    if (query.has(FaqKeys.UrlParam)) {
      query.delete(FaqKeys.UrlParam);
      history.replace({ search: query.toString() });
    }
    setIsOpen(false);
  }, [history, query]);

  const handleAnimationEnd = ({
    animationName,
  }: AnimationEvent<HTMLDivElement>) => {
    const lastAnimationName = 'slide-out';
    if (animationName === lastAnimationName) setIsMounted(false);
  };

  useEffect(() => {
    if (query.get(FaqKeys.UrlParam) && !isOpen) open();
  }, [isOpen, open, query]);

  return (
    <FaqModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        open,
        close,
      }}
    >
      <div
        className={classNames(
          { animate: isMounted },
          isOpen
            ? 'blur-out h-screen overflow-hidden'
            : 'blur-in overflow-y-visible',
        )}
      >
        {children}
      </div>
      <div
        className={classNames(
          'faq fixed z-50 w-full h-full',
          { animate: isMounted },
          isOpen ? 'slide-in overflow-auto' : 'slide-out overflow-hidden',
        )}
        onAnimationEnd={handleAnimationEnd}
      >
        {isMounted && <FaqPage />}
      </div>
    </FaqModalContext.Provider>
  );
};

export const useFaqModal = (): FaqModalProps => useContext(FaqModalContext);

export default FaqModalProvider;
