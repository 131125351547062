import React, { FC, memo, ReactNode, useRef } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';

interface SmoothScrollParams {
  children?: ReactNode;
  direction?: 'horizontal' | 'vertical';
}

export const SmoothScroll: FC<SmoothScrollParams> = ({
  children,
  direction = 'vertical',
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <LocomotiveScrollProvider
      options={{
        smooth: true,
        gestureDirection: 'both',
        smartphone: {
          smooth: true,
          breakpoint: 0,
          lerp: 0.5,
          horizontalGesture: true,
          direction,
          gestureDirection: 'both',
        },
        tablet: {
          smooth: true,
          breakpoint: 0,
          lerp: 0.5,
          horizontalGesture: true,
          direction,
          gestureDirection: 'both',
        },
        direction,
        lerp: 0.07,
      }}
      watch={[]}
      containerRef={scrollContainerRef}
    >
      <div data-scroll-container ref={scrollContainerRef}>
        {children}
      </div>
    </LocomotiveScrollProvider>
  );
};

export default memo(SmoothScroll);
