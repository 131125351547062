import { ExhibitionLogo } from 'components/ExhibitionLogo';
import { motion, VariantLabels } from 'framer-motion';
import useCheckAnimations from 'hooks/useCheckAnimations';
import useInitialLoad from 'hooks/useInitialLoad';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement, useEffect, useState } from 'react';
import { useExhibitionsList } from '../hooks/useExhibitionsList';
import { ExhibitionSummary } from '../types/Exhibition';
import variants from './withExhibitionsList.variants';

const withExhibitionsList =
  <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const initialLoad = useInitialLoad();
    const animationsSupported = useCheckAnimations();
    const [exhibitions, , error] = useExhibitionsList();
    const [animation, setAnimation] = useState<VariantLabels>('loading');
    const [introComplete, setIntroComplete] = useState<boolean>(
      !initialLoad || !animationsSupported,
    );

    useLayoutConfig({
      withoutHeader: true,
    });

    useEffect(() => {
      if (exhibitions) setAnimation('loaded');
    }, [exhibitions]);

    const handleAnimationEnd = () => {
      if (animation == 'loaded') {
        setIntroComplete(true);
      }
    };

    return (
      <>
        {!introComplete && (
          <motion.div className="fixed h-screen w-screen z-50 text-xs xl:text-xxs px-9 py-5">
            <motion.div
              variants={variants}
              initial="loading"
              animate={animation}
              onAnimationComplete={handleAnimationEnd}
              className="z-50"
              style={{ transform: 'translateZ(0)' }}
            >
              <ExhibitionLogo animate />
            </motion.div>
          </motion.div>
        )}
        {!exhibitions && introComplete && <PendingPage error={error} />}
        {exhibitions && (
          <WrappedComponent
            exhibitions={exhibitions as ExhibitionSummary[]}
            introComplete={introComplete}
            {...props}
          />
        )}
      </>
    );
  };

export default withExhibitionsList;
