import { Button } from 'components/Button';
import { Close } from 'icons/Close';
import React, { FC, HTMLAttributes } from 'react';
import './CookiesPolicy.scss';
import { useCookiesPolicy } from './CookiesPolicyProvider';

type CookiesPolicyProps = HTMLAttributes<HTMLDivElement>;

export const CookiesPolicy: FC<CookiesPolicyProps> = (props) => {
  const { accept, dismiss } = useCookiesPolicy();

  return (
    <div className="cookies-policy text-xxs leading-none px-3" {...props}>
      <span className="flex-grow whitespace-nowrap mr-8">
        This site uses cookies
      </span>
      <Button
        variant="link"
        upperCase
        borderLess
        withoutPadding
        className="mx-5"
        onClick={accept}
      >
        Accept
      </Button>
      <Button
        variant="link"
        upperCase
        borderLess
        withoutPadding
        onClick={dismiss}
      >
        <Close className="w-4 h-4" />
      </Button>
    </div>
  );
};

export default CookiesPolicy;
