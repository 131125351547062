import { useEffect } from 'react';
import { useLocation } from 'react-router';
import useLocalStorage from './useLocalStorage';

const useRestoreScroll = (
  key: string,
): [number, () => ReturnType<typeof removeStoredScroll>] => {
  const { state } = useLocation<{ scroll?: number }>();
  const [storedScroll, setStoredScroll, removeStoredScroll] = useLocalStorage(
    key,
    0,
  );

  useEffect(() => {
    if (state && state.scroll) {
      setStoredScroll(state.scroll);
    }
  }, [setStoredScroll, state]);

  return [storedScroll, removeStoredScroll];
};

export default useRestoreScroll;
