import jwt from 'jwt-decode';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

type StoreTokenCallback = (token: string) => void;
type RemoveTokenCallback = () => void;
type PreviewModeToken = {
  userEmail: string;
  sub: number;
  isPreview: boolean;
  iat: number;
  exp: number;
};

function usePreviewModeToken(): [
  string,
  StoreTokenCallback,
  RemoveTokenCallback,
] {
  const tokenStoreKey = 'vsco-preview-mode';
  const [cookie, setCookie, removeCookie] = useCookies([tokenStoreKey]);
  const storedToken: string = cookie[tokenStoreKey];

  const storeToken = useCallback<StoreTokenCallback>(
    (token) => {
      if (!token) return;

      const decodedToken = jwt<PreviewModeToken>(token);
      setCookie(tokenStoreKey, token, {
        expires: new Date(decodedToken.exp * 1000),
      });
    },
    [setCookie, tokenStoreKey],
  );

  const removeToken = useCallback<RemoveTokenCallback>(() => {
    removeCookie(tokenStoreKey);
  }, [removeCookie]);

  return [storedToken, storeToken, removeToken];
}

export default usePreviewModeToken;
