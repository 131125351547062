import classNames from 'classnames';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import useRestoreScroll from 'hooks/useRestoreScroll';
import useWindowSize from 'hooks/useWindowSize';
import ArrowRight from 'icons/ArrowRight';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import './Slider.scss';

interface SliderProps {
  disableDrag?: boolean;
  loaded?: boolean;
}

const Slider: React.FC<SliderProps> = (props) => {
  const { children, loaded, disableDrag = false } = props;

  const { width, height } = useWindowSize();
  const [scrollBy, setScrollBy] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [canDrag, setCanDrag] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);
  const [maxRange, setMaxRange] = useState(width);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [sliderRangeSize, setSliderRangeSize] = useState(0);
  const { scroll, isReady } = useLocomotiveScroll();
  const isMobile = useCheckMobileScreen();
  const sliderWidth = isMobile ? 0.8 : 0.3;
  const [storedScroll, removeStoredScroll] = useRestoreScroll('gallery-scroll');

  const handleRangeScroll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const offset = +e.target.value;
    scrollTo(offset);
    setScrollBy(offset);
  };

  const scrollTo = useCallback(
    (offset: number, duration?: number) => {
      if (!shouldScroll || !scrollContainerRef.current) return;
      if (!duration) duration = isDragging ? 5 : 200;
      scroll.scrollTo(scrollContainerRef.current, { offset, duration });
    },
    [isDragging, scroll, shouldScroll],
  );

  useEffect(() => {
    if (loaded && storedScroll) {
      scrollTo(storedScroll);
      removeStoredScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, storedScroll]);

  useEffect(() => {
    if (!isReady) return;
    function handleScroll(obj: any): void {
      const offset = Math.floor(obj.scroll.x);
      setScrollBy(offset);
    }

    scroll.on('scroll', handleScroll);
    return () => scroll.destroy();
  }, [isReady, scroll]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      setShouldScroll(width < scrollContainer.clientWidth);
      setMaxRange(scrollContainer.clientWidth - width);
      setSliderRangeSize(
        Math.round((width / scrollContainer.clientWidth) * 100),
      );
    }
  }, [children, width]);

  const handleMouseDown = useCallback(
    (event: any) => {
      setCanDrag(true);
      setDragStart(event.clientX || event.pageX || event.screenX);
      setScrollStart(scrollBy);
    },
    [scrollBy],
  );

  const handleMouseMove = useCallback(
    (event: any) => {
      if (!canDrag || disableDrag) return;

      event.preventDefault();
      event.stopPropagation();
      const xPos = event.clientX || event.pageX || event.screenX;
      const offset = scrollStart + (dragStart - xPos) * 2;
      const dragging = Math.abs(offset) > 20;
      if (dragging) {
        setIsDragging(true);
      }
      scrollTo(offset, dragging ? 5 : 200);
      setScrollBy(offset);
    },
    [canDrag, disableDrag, dragStart, scrollStart, scrollTo],
  );

  const handleMouseUp = useCallback(() => {
    setCanDrag(false);
    setTimeout(() => {
      setIsDragging(false);
    }, 50);
  }, []);

  const shouldHideArrow = useCallback(() => {
    const container = scrollContainerRef.current;
    if (container?.clientWidth) {
      return scrollBy > 100 || scrollBy + width + 10 >= container.clientWidth;
    }
    return scrollBy > 100;
  }, [scrollBy, width]);

  return (
    <div
      data-scroll-section
      ref={scrollContainerRef}
      style={{ cursor: 'ew-resize' }}
      className="slider"
    >
      <div
        id="slider"
        className="horizontal-scroll"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{ height }}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement<any>, {
            scroll: scrollBy,
          }),
        )}
      </div>
      <input
        data-scroll
        data-scroll-sticky
        data-scroll-target="#slider"
        type="range"
        max={maxRange}
        min={0}
        onChange={handleRangeScroll}
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        className={classNames('slider-range z-50 opacity-0', {
          'fade-in': shouldScroll && loaded,
        })}
        value={scrollBy}
        style={
          {
            '--slider-range-size': `${sliderRangeSize}%`,
            left: width / 2 - width * (sliderWidth / 2),
            top: `calc(${height}px - 2rem)`,
          } as React.CSSProperties
        }
      />
      <div
        data-scroll
        data-scroll-sticky
        data-scroll-target="#slider"
        className="fixed z-50"
        style={{
          display: 'inline-block',
          zIndex: 500,
          top: '50%',
          left: width,
        }}
      >
        <ArrowRight
          onClick={() => scrollTo(width / 2)}
          className={classNames(
            'gallery-arrow transition-transform cursor-pointer',
            { 'fade-out': shouldHideArrow() },
            { 'fade-in': shouldScroll && loaded && scrollBy < 100 },
          )}
        />
      </div>
    </div>
  );
};

export default Slider;
