import { Nullable } from 'modules/global/types/types';
import React, { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import formatDate from 'utils/format-date';

interface CountdownProps extends HTMLAttributes<HTMLSpanElement> {
  toDate?: string;
  targetFormat?: string;
  onEnd?: () => void;
}

const DEFAULT_DATE_FORMAT = 'HH:mm:ss';
export const Countdown: FC<CountdownProps> = ({
  toDate = '',
  targetFormat = DEFAULT_DATE_FORMAT,
  onEnd = () => null,
  ...props
}) => {
  const [countdown, setCountdown] = useState<Nullable<string>>(null);
  const timer = useRef<any>();

  const tick = () => {
    const [delta, lastCountdown] = formatDate(toDate, targetFormat);

    if (delta <= 0) {
      clearInterval(timer.current);
      timer.current = null;
      onEnd();
    } else {
      setCountdown(lastCountdown);
    }
  };

  useEffect(() => {
    tick();
    timer.current = setInterval(tick, 1000);

    return () => clearInterval(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <span {...props}>{countdown}</span>;
};

export default Countdown;
