import MyAlgo from '@randlabs/myalgo-connect';
import map from 'lodash/map';
import { useState } from 'react';
import { Account, WalletTransaction } from '../types/Algorand';

interface useMyAlgoDataParams {
  accounts?: Account[];
  connect: () => Promise<void>;
  connected: boolean;
  disconnect: () => Promise<void>;
  signTxn: (transactions: any) => Promise<any>;
}

const useMyAlgoData = (): useMyAlgoDataParams => {
  const [accounts, setAccounts] = useState<Account[]>();
  const [connected, setConnected] = useState(false);
  const myAlgoWallet = new MyAlgo();

  const connect = async () => {
    try {
      const accounts = await myAlgoWallet.connect();
      setConnected(true);
      setAccounts(accounts);
    } catch (error) {
      console.error(error);
    }
  };

  const disconnect = async () => setConnected(false);

  const signTxn = async (transactions: WalletTransaction[]) => {
    transactions.pop();
    const transactionsToSign = map(transactions, 'txn');
    return myAlgoWallet.signTransaction(transactionsToSign);
  };

  return {
    connected,
    accounts,
    connect,
    disconnect,
    signTxn,
  };
};

export default useMyAlgoData;
