import { fileSerializer } from 'modules/global/serializers/fileSerializer';
import { ExhibitionStatus, ExhibitionSummary } from '../types/Exhibition';

export const exhibitionListSerializer = (
  exhibition: ExhibitionSummary,
): ExhibitionSummary => {
  const preview = fileSerializer(exhibition.preview);
  const backgroundImage = fileSerializer(exhibition.backgroundImage);
  return {
    ...exhibition,
    backgroundImage,
    preview,

    isActive: exhibition.status === ExhibitionStatus.Active,
    isUpcoming: exhibition.status === ExhibitionStatus.Future,
    isClosed: exhibition.status === ExhibitionStatus.Ended,
    title: preview.shortTitle,
  };
};
