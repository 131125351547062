import React, { FC, HTMLAttributes } from 'react';

export const Dot: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="0.38em"
    height="0.38em"
    {...props}
  >
    <circle cx="2.73913" cy="2.73913" r="2.73913" fill="currentColor" />
  </svg>
);
