import { Button } from 'components/Button';
import { ButtonProps } from 'components/Button/Button';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import React, { FC, useMemo } from 'react';
import { Item } from '../../types/Item';

interface PurchaseButtonsProps {
  item: Item;
  buttonProps: ButtonProps;
  handleClick: () => void;
}

export const PurchaseButtons: FC<PurchaseButtonsProps> = ({
  item,
  buttonProps,
  handleClick,
}) => {
  const { displayPrice, quantity, availableQuantity, currency } = item;
  const isMobile = useCheckMobileScreen();

  const availableLabel = useMemo(
    () =>
      isMobile
        ? [availableQuantity, 'Left'].join(' ')
        : [availableQuantity, 'of', quantity, 'Left'].join(' '),
    [availableQuantity, isMobile, quantity],
  );

  if (!quantity)
    return (
      <Button {...buttonProps}>
        <span>Not for sale</span>
      </Button>
    );

  return (
    <>
      <Button variant="text" {...buttonProps}>
        <span>{availableLabel}</span>
      </Button>
      <Button variant="text" {...buttonProps}>
        <span>{[displayPrice, currency].join(' ')}</span>
      </Button>
      {!item.availableQuantity ? (
        <Button
          rounded
          bold
          upperCase
          style={{ maxWidth: '9.4rem' }}
          {...buttonProps}
        >
          <span>Sold out</span>
        </Button>
      ) : (
        <Button
          rounded
          bold
          upperCase
          onClick={handleClick}
          style={{ maxWidth: '5rem', flexGrow: 1 }}
          {...buttonProps}
        >
          <span>Buy</span>
        </Button>
      )}
    </>
  );
};

export default PurchaseButtons;
