export type FileMimeType = 'image/*' | 'video/*' | 'application/x-mpegURL';

export const FileMimeTypes: Record<string, FileMimeType> = {
  Image: 'image/*',
  Video: 'video/*',
  HLS: 'application/x-mpegURL',
};

export interface File {
  name: string;
  authorName: string;
  createdDate: string;
  description: string;
  mimeType: FileMimeType;
  path: string;
}

export interface FilePreview extends File {
  year?: string;
  title?: string;
  isVideo?: boolean;
  isImage?: boolean;
  shortTitle?: string;
}
