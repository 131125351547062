import { FileInfo } from 'components/FileInfo';
import { ITEM_CHECKOUT_ROUTE } from 'constants/routes';
import { motion } from 'framer-motion';
import useInitialLoad from 'hooks/useInitialLoad';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { FullPagePreview } from 'modules/Exhibition/components/FullPagePreview';
import { Preview } from 'modules/Exhibition/components/Preview';
import useParentRouteParams from 'modules/global/hoc/useParentRouteParams';
import { Checkout } from 'modules/Item/components/Checkout';
import withItemDetails from 'modules/Item/hoc/withItemDetails';
import { useItemSlugInURL } from 'modules/Item/hooks/useItemSlugInURL';
import { Item } from 'modules/Item/types/Item';
import React, { FC, useState } from 'react';
import './CheckoutPage.scss';
import variants from './CheckoutPage.variants';

interface CheckoutPageProps {
  item: Item;
}

const CheckoutPage: FC<CheckoutPageProps> = ({ item }) => {
  const initialLoad = useInitialLoad();
  const [loaded, setLoaded] = useState(false);
  useParentRouteParams({ id: item.id });
  useLayoutConfig({
    className: 'white-text-opened-menu',
    HeaderProps: {
      text: (
        <FileInfo name={item.title} author={item.seller?.name} center={true} />
      ),
      fadeIn: true,
      withBackButton: true,
    },
  });
  useItemSlugInURL(item, ITEM_CHECKOUT_ROUTE);

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <div className="absolute inset-0 flex-center max-h-screen pt-28">
      <motion.div
        variants={variants}
        initial="initial"
        animate={loaded ? 'loaded' : 'initial'}
        transition={{
          duration: initialLoad ? 0 : 0.5,
        }}
        className="bg-preview-container"
      >
        <Preview
          preview={item.preview}
          className="bg-preview"
          style={{ transform: item.preview.isImage ? 'scale(1.5)' : '' }}
          onLoaded={handleImageLoaded}
        />
      </motion.div>
      <motion.div
        variants={variants}
        initial="initial"
        animate={loaded ? 'loaded' : 'initial'}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
      >
        <div className="flex-center flex-col py-10 w-1/2 md:w-52 mr-auto ml-auto">
          <FullPagePreview
            className="pointer-events-auto flex-grow"
            preview={item.file}
          />
          <Checkout item={item} />
        </div>
      </motion.div>
    </div>
  );
};

export default withItemDetails(CheckoutPage);
