import React, { FC, HTMLAttributes } from 'react';

export const Close: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="1.31em"
    height="1.25em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <line
      x1="0.646447"
      y1="19.6464"
      x2="19.6464"
      y2="0.646447"
      stroke="currentColor"
    />
    <line
      x1="1.35355"
      y1="0.646447"
      x2="20.3536"
      y2="19.6464"
      stroke="currentColor"
    />
  </svg>
);
