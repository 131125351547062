import {
  Exhibition,
  ExhibitionResponse,
  ExhibitionStatus,
} from 'modules/Exhibition/types/Exhibition';
import { fileSerializer } from 'modules/global/serializers/fileSerializer';
import { itemSerializer } from 'modules/Item/serializers/itemSerializer';

export const exhibitionSerializer = (
  exhibition: ExhibitionResponse,
): Exhibition => ({
  ...exhibition,
  items: exhibition.items?.map(itemSerializer),
  backgroundImage: fileSerializer(exhibition.backgroundImage),
  preview: fileSerializer(exhibition.preview),

  isActive: exhibition.status === ExhibitionStatus.Active,
  isUpcoming: exhibition.status === ExhibitionStatus.Future,
  isClosed: exhibition.status === ExhibitionStatus.Ended,
});
