import truncate from 'lodash/truncate';
import {
  FileMimeType,
  FileMimeTypes,
  FilePreview,
} from 'modules/global/types/File';
import { getYear } from 'utils/format-date';

const withUnknownSupport = (text: string): string => text || 'Unknown';

function isImageByMimeType(mimeType: FileMimeType): boolean {
  if (!mimeType) return false;
  return new RegExp(FileMimeTypes.Image).test(mimeType);
}

function isVideoByMimeType(mimeType: FileMimeType): boolean {
  if (!mimeType) return false;
  const allowedVideoTypes = [FileMimeTypes.Video, FileMimeTypes.HLS];
  return allowedVideoTypes.some((allowedVideoType) =>
    new RegExp(allowedVideoType).test(mimeType),
  );
}

export const fileSerializer = (file: FilePreview): FilePreview => ({
  ...file,
  year: getYear(file?.createdDate),
  authorName: withUnknownSupport(file?.authorName),
  title: [withUnknownSupport(file?.description), getYear(file?.createdDate)]
    .filter(Boolean)
    .join(', '),
  shortTitle: withUnknownSupport(
    truncate(file?.description, {
      length: 50,
      separator: ' ',
    }),
  ),
  isImage: isImageByMimeType(file?.mimeType),
  isVideo: isVideoByMimeType(file?.mimeType),
});
