import { motion } from 'framer-motion';
import { Preview } from 'modules/Exhibition/components/Preview';
import { PreviewWrapper } from 'modules/Exhibition/components/PreviewWrapper';
import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { Curator } from '../../types/Curator';

interface CuratorPreviewProps extends HTMLAttributes<HTMLDivElement> {
  curator: Curator;
}

const container = {
  initial: {
    opacity: 0,
    y: 8,
  },
  load: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.8,
      ease: [0.24, 0.8, 0, 0.99],
      staggerChildren: 0.03,
    },
  },
};

const child = {
  initial: {
    opacity: 0,
    y: 8,
  },
  load: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.8,
      ease: [0.24, 0.8, 0, 0.99],
    },
  },
};

export const CuratorPreview: FC<CuratorPreviewProps> = ({ curator }) => {
  const { profilePicture, name, jobTitle } = curator;
  const [animation, setAnimation] = useState('initial');

  useEffect(() => {
    setAnimation('load');
  }, []);

  return (
    <div
      className="sticky top-0 flex-center flex-col h-screen"
      data-scroll-section
      data-scroll
      data-scroll-speed={0}
      style={{ zIndex: 0 }}
    >
      <h1 className="mt-20 flex-center flex-col flex-1">
        <motion.div
          variants={container}
          initial="initial"
          animate={animation}
          className="relative text-center uppercase my-5"
          style={{
            fontSize: '10vw',
            lineHeight: '10vw',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {name.split(' ').map((word, index) => {
            return (
              <span
                key={`${index}-${word}`}
                style={{ marginRight: '3vw', whiteSpace: 'nowrap' }}
              >
                {word.split('').map((letter, index) => {
                  return (
                    <motion.span
                      variants={child}
                      key={`${letter}-${index}`}
                      className="relative inline-block"
                    >
                      {letter}
                    </motion.span>
                  );
                })}
              </span>
            );
          })}
        </motion.div>
      </h1>

      {profilePicture && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="flex-1"
        >
          <PreviewWrapper width="20rem">
            <Preview preview={profilePicture} />
          </PreviewWrapper>
        </motion.div>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="absolute bottom-0 p-5 w-full flex-center flex-col xl:items-start"
      >
        <span className="font-medium">{name}</span>
        <span>{jobTitle}</span>
      </motion.div>
    </div>
  );
};

export default CuratorPreview;
