import { Button } from 'components/Button';
import { apiErrors } from 'constants/apiErrors';
import { TRANSACTION_COVER_ROUTE } from 'constants/routes';
import { usePreviewMode } from 'hooks/usePreviewMode';
import { Pending } from 'icons/Pending';
import first from 'lodash/first';
import get from 'lodash/get';
import size from 'lodash/size';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import useTransactionService from 'modules/Transaction/hooks/useTransactionService';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { Item } from '../../types/Item';
import { PaymentRulesAgreement } from '../PaymentRulesAgreement';
import { WalletSelect } from '../WalletSelect';

interface BuyButtonProps {
  item: Item;
  quantity: number;
}

export const BuyButton: FC<BuyButtonProps> = ({ item, quantity }) => {
  const { connected, accounts } = useAlgorand();
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [pending, setPending] = useState(false);
  const transaction = useTransactionService();
  const accountsSize = useMemo(() => size(accounts), [accounts]);
  const history = useHistory();
  const [showPaymentRulesAgreement, setShowPaymentRulesAgreement] =
    useState(false);

  const purchaseSucceeded = useCallback(
    (params: Record<string, any>) =>
      history.push(generatePath(TRANSACTION_COVER_ROUTE, params)),
    [history],
  );

  const isQuantityValid = useCallback(
    () => quantity >= 1 && quantity <= item.availableQuantity,
    [item.availableQuantity, quantity],
  );

  const handleBuyClick = useCallback(() => {
    if (isQuantityValid() && connected && selectedAddress) {
      setShowPaymentRulesAgreement(true);
    }
  }, [connected, isQuantityValid, selectedAddress]);

  const purchaseTransaction = useCallback(async () => {
    if (!connected || !selectedAddress) return;

    try {
      setShowPaymentRulesAgreement(false);
      setPending(true);
      const response = await transaction.purchaseTransaction({
        itemId: item.id,
        address: selectedAddress,
        quantity,
      });
      purchaseSucceeded({
        publicWalletAddress: response.publicWalletAddress,
        transactionId: response.id,
      });
      setPending(false);
    } catch (e) {
      const error = e as Error;
      setPending(false);
      //TODO Error handling
      const code: keyof typeof apiErrors = get(e, 'response.data.message');
      alert(apiErrors[code] || error.message.replace(/\[.*\] /gm, ''));
    }
  }, [
    connected,
    item.id,
    purchaseSucceeded,
    quantity,
    selectedAddress,
    transaction,
  ]);

  useEffect(() => {
    if (accounts && accounts.length === 1) {
      setSelectedAddress(first(accounts)?.address);
    }
  }, [accounts]);

  const [isPreviewMode] = usePreviewMode();
  if (isPreviewMode) return <></>;

  return (
    <>
      {accountsSize > 1 && (
        <WalletSelect
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      {!accountsSize && <span> No wallets</span>}
      <Button
        onClick={handleBuyClick}
        className="flex-center mt-4"
        disabled={pending || !selectedAddress}
        rounded
      >
        {pending ? <Pending className="animate-spin" /> : 'PLACE BUY'}
      </Button>
      {showPaymentRulesAgreement && (
        <PaymentRulesAgreement onConfirm={purchaseTransaction} />
      )}
    </>
  );
};

export default BuyButton;
