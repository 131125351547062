import { Button } from 'components/Button';
import { ButtonProps } from 'components/Button/Button';
import ArrowDown from 'icons/ArrowDown';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import { Transaction } from 'modules/Transaction/types/Transaction';
import React, { FC, useCallback } from 'react';
import { downloadFile } from 'utils/utils';

interface DownloadButtonsProps {
  transaction: Transaction;
  buttonProps: ButtonProps;
}

export const DownloadButtons: FC<DownloadButtonsProps> = ({
  transaction,
  buttonProps,
}) => {
  const { isAddressOwned } = useAlgorand();
  const { quantity, item, publicWalletAddress } = transaction;

  const handleDownloadClick = useCallback(() => {
    downloadFile(item.file.path, item.file.name);
  }, [item]);

  return (
    <>
      {isAddressOwned(publicWalletAddress) && (
        <Button variant="text" {...buttonProps}>
          <span>{[quantity, 'owned'].join(' ')}</span>
        </Button>
      )}
      <Button variant="text" {...buttonProps}>
        <span>{[item.displayPrice, item.currency].join(' ')}</span>
      </Button>
      {isAddressOwned(publicWalletAddress) && (
        <Button
          variant="button"
          style={{ height: '3.125rem', flexGrow: 1, padding: '0 2.5rem' }}
          onClick={handleDownloadClick}
          {...buttonProps}
        >
          <ArrowDown />
        </Button>
      )}
    </>
  );
};

export default DownloadButtons;
