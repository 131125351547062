import { Button } from 'components/Button';
import { usePreviewMode } from 'hooks/usePreviewMode';
import { ChevronDown } from 'icons/ChevronDown';
import { ChevronUp } from 'icons/ChevronUp';
import useAlgoSignerData from 'modules/Algorand/hooks/useAlgoSignerData';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import { useFaqModal } from 'modules/Faq/providers/FaqModalProvider';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Item } from '../../types/Item';
import { BuyButton } from '../BuyButton';

interface CheckoutProps {
  item: Item;
}

export const Checkout: FC<CheckoutProps> = ({ item }) => {
  const [quantity, setQuantity] = useState(1);
  const { connected, openConnectSide, connect, error } = useAlgorand();
  const { isPluginAvailable } = useAlgoSignerData();
  const { open: openFaqModal } = useFaqModal();
  const [isPreviewMode] = usePreviewMode();

  const handleConnectClick = useCallback(() => {
    isPluginAvailable ? openConnectSide() : connect();
  }, [connect, isPluginAvailable, openConnectSide]);

  useEffect(() => {
    if (error) openConnectSide();
  }, [error, openConnectSide]);

  if (!item.quantity)
    return (
      <Button upperCase className="my-5 font-bold px-10 cursor-not-allowed">
        Not for sale
      </Button>
    );

  if (!item.availableQuantity)
    return (
      <Button upperCase className="my-5 font-bold px-10 cursor-not-allowed">
        Sold out
      </Button>
    );

  if (isPreviewMode)
    return (
      <span className="my-5 text-center">{`Sorry, you can't purchase in preview mode`}</span>
    );

  if (!connected)
    return (
      <div className="flex flex-center flex-col">
        <span className="my-5 text-center">
          Connect your Algorand Wallet to place buy
        </span>
        <Button onClick={handleConnectClick} rounded upperCase>
          Connect Wallet
        </Button>
        <Button onClick={openFaqModal} borderLess variant="link">
          New? Learn More
        </Button>
      </div>
    );

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <span className="mt-10 mb-4">
        {item.displayPrice} {item.currency}
      </span>
      <div className="flex-center">
        <span className="select-none my-4 mr-2">{quantity}</span>
        <Button
          disabled={quantity === 1}
          onClick={() => setQuantity(quantity - 1)}
          className="border-none"
          withoutPadding
        >
          <ChevronDown />
        </Button>
        <Button
          disabled={quantity === item.availableQuantity}
          onClick={() => setQuantity(quantity + 1)}
          className="border-none"
          withoutPadding
        >
          <ChevronUp />
        </Button>
      </div>
      <BuyButton item={item} quantity={quantity} />
    </div>
  );
};

export default Checkout;
