import { useTransactionDetails } from 'modules/Transaction/hooks/useTransactionDetails';
import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement } from 'react';
import { useParams } from 'react-router';

interface TransactionDetailsParams {
  transactionId: string;
  publicWalletAddress: string;
}

const withTransactionDetails =
  <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { transactionId, publicWalletAddress } =
      useParams<TransactionDetailsParams>();
    const [transaction] = useTransactionDetails({
      publicWalletAddress,
      transactionId,
    });

    return (
      <>
        {transaction ? (
          <WrappedComponent transaction={transaction} {...props} />
        ) : (
          <PendingPage />
        )}
      </>
    );
  };

export default withTransactionDetails;
