import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import './Button.scss';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  bold?: boolean;
  borderLess?: boolean;
  disabled?: boolean;
  minHeight?: string;
  rounded?: boolean;
  upperCase?: boolean;
  variant?: 'button' | 'text' | 'link';
  withoutPadding?: boolean;
}

export const Button: FC<ButtonProps> = ({
  bold = false,
  borderLess = false,
  children,
  className,
  disabled = false,
  minHeight = '2.5rem',
  rounded = false,
  upperCase = false,
  variant = 'button',
  withoutPadding = false,
  style,
  ...props
}) => {
  return (
    <button
      type="button"
      style={{ ...style, minHeight }}
      className={classNames(
        'flex justify-center items-center text-center select-none whitespace-nowrap',
        {
          'cursor-default': variant !== 'button',
          'button-link cursor-pointer': variant === 'link',
          'py-4 px-5 leading-4': !withoutPadding,
          'font-medium': bold,
          'rounded-full': rounded,
          uppercase: upperCase,
          'border border-current': !borderLess,
          'opacity-50 pointer-events-none cursor-not-allowed': disabled,
        },
        className,
      )}
      {...props}
    >
      {variant === 'link' && (
        <span
          className={classNames({
            'button-link-underline': variant === 'link',
          })}
        >
          {children}
        </span>
      )}
      {variant !== 'link' && children}
    </button>
  );
};

export default Button;
