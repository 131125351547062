import { useAppLayoutTheme } from 'layout/AppLayout/AppLayoutProvider';
import { useEffect } from 'react';

const useParentRouteParams = (
  params: Record<string, string | number>,
): void => {
  const { setParentRouteParams, cleanUpLayout } = useAppLayoutTheme();

  useEffect(() => {
    setParentRouteParams(params);
    return () => {
      cleanUpLayout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useParentRouteParams;
