import VSCOFaceSad from 'icons/VSCOFaceSad';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import React, { FC, HTMLAttributes } from 'react';

const CHROME_PLUGIN_LINK = process.env.REACT_APP_CHROME_ALGOSIGNER_LINK;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InstallPluginProps extends HTMLAttributes<HTMLDivElement> {}

export const InstallPlugin: FC<InstallPluginProps> = () => {
  const { reset: handleTryAgainClick } = useAlgorand();

  return (
    <>
      <VSCOFaceSad />
      <div className="flex flex-col">
        <span>Algosigner plugin in needed to connect wallet.</span>
        <span
          className="underline cursor-pointer"
          onClick={handleTryAgainClick}
        >
          Try again.
        </span>
      </div>
      <a className="box uppercase" href={CHROME_PLUGIN_LINK} target="blank">
        Install plugin
      </a>
    </>
  );
};

export default InstallPlugin;
