import { Curator } from 'modules/Curator/types/Curator';
import { FilePreview } from 'modules/global/types/File';
import { Item, ItemBase } from 'modules/Item/types/Item';

export interface ExhibitionBase {
  id: string | number;
  curator: Curator;
  name: string;
  verbName: string;
  startAt: string;
  endAt: string;
  status: ExhibitionStatus;
  backgroundImage: FilePreview;
  items: ItemBase[];
  preview: FilePreview;
  description: string;
  year: string;
  hasPassword: boolean;
  loaded?: boolean;
}

export type ExhibitionResponse = ExhibitionBase;

export interface ExtendedExhibition extends ExhibitionBase {
  isActive: boolean;
  isUpcoming: boolean;
  isClosed: boolean;
  title?: string;
  items: Item[];
  slug?: string;
}

export type Exhibition = ExtendedExhibition;

export interface ExhibitionSummary extends Omit<ExtendedExhibition, 'items'> {
  authorCounts: number;
  items: null;
  itemsCounts: number;
  netWorth: number;
}

export enum ExhibitionStatus {
  Future = 'FUTURE',
  Active = 'ACTIVE',
  Ended = 'ENDED',
}

export interface ExhibitionRouteParams {
  id: string;
  slug: string;
}
