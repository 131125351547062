import { Nullable } from 'modules/global/types/types';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import usePreviewModeToken from './usePreviewModeToken';
import useQuery from './useQuery';

export const PREVIEW_MODE_TOKEN_KEY = `token`;

type PreviewContextType = Nullable<[boolean, Nullable<string>, () => void]>;

const UsePreviewModeContext = createContext<PreviewContextType>(null);

const PreviewModeProvider: FC = ({ children }) => {
  const history = useHistory();
  const query = useQuery();
  const { pathname } = useLocation();
  const token = query.get(PREVIEW_MODE_TOKEN_KEY);
  const [storedToken, storePreviewModeToken, removePreviewModeToken] =
    usePreviewModeToken();
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(!!storedToken);

  useEffect(() => {
    if (token) {
      storePreviewModeToken(token);
      history.replace(pathname);
      history.go(0);
    }
  }, [history, pathname, storePreviewModeToken, token]);

  const closePreviewMode = useCallback(() => {
    removePreviewModeToken();
    setIsPreviewMode(false);
    history.replace(pathname);
    history.go(0);
  }, [history, pathname, removePreviewModeToken]);

  return (
    <UsePreviewModeContext.Provider
      value={[isPreviewMode, token, closePreviewMode]}
    >
      {isPreviewMode && (
        <div className="fixed top-0 inset-x-0 bg-yellow-500 text-white text-sm z-50 flex items-center justify-between px-2">
          <span className="w-1/3"></span>
          <span>Preview mode</span>
          <button className="w-1/3 text-right" onClick={closePreviewMode}>
            Exit preview mode
          </button>
        </div>
      )}
      {children}
    </UsePreviewModeContext.Provider>
  );
};

export const usePreviewMode = (): any => useContext(UsePreviewModeContext);

export default PreviewModeProvider;
