import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { Transaction } from 'modules/Transaction/types/Transaction';
import { TransactionItem } from 'modules/Wallet/components/TransactionItem';
import React, { FC } from 'react';

interface WalletPageProps {
  transactions?: Required<Transaction>[];
}

const FADE_IN_DELAY = 200;

export const WalletPage: FC<WalletPageProps> = ({ transactions }) => {
  useLayoutConfig({
    HeaderProps: {
      text: 'My collection',
      className: 'uppercase',
      withBackButton: true,
    },
    withFooter: true,
  });

  return (
    <div className="mt-28 xl:mt-36 mb-10 mx-5 flex flex-wrap">
      {transactions?.map((transaction, index) => (
        <TransactionItem
          className="w-1/2 xl:w-1/3 xl:min-h-xl opacity-0 animate animate-fade-in"
          key={`transaction-${transaction.id}`}
          transaction={transaction}
          style={{ animationDelay: `${index * FADE_IN_DELAY}ms` }}
        />
      ))}
    </div>
  );
};

export default WalletPage;
