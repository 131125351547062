import { EXHIBITIONS_API_PATH } from 'constants/apiPaths';
import useAxios from 'hooks/useAxios';
import { ResponseList } from 'modules/global/types/types';
import { useEffect, useState } from 'react';
import { exhibitionListSerializer } from '../serializers/exhibitionListSerializer';
import { ExhibitionSummary } from '../types/Exhibition';

export const useExhibitionsList = ():
  | [ExhibitionSummary[] | undefined, boolean, boolean] => {
  const { response, isLoading, isError } =
    useAxios<ResponseList<ExhibitionSummary>>(EXHIBITIONS_API_PATH);
  const [exhibitions, setExhibitions] = useState<ExhibitionSummary[]>();

  useEffect(() => {
    if (response) {
      setExhibitions(response.items.map(exhibitionListSerializer));
    }
  }, [response]);

  return [exhibitions, isLoading, isError];
};
