import classNames from 'classnames';
import { Button, ButtonProps } from 'components/Button/Button';
import React, { FC } from 'react';
import './MenuButton.scss';

interface MenuButtonProps extends ButtonProps {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
}

export const MenuButton: FC<MenuButtonProps> = ({
  menuOpen,
  setMenuOpen,
  className,
  ...props
}) => (
  <Button
    onClick={() => setMenuOpen(!menuOpen)}
    className={classNames(`menu-btn p-0`, className, {
      open: menuOpen,
    })}
    {...props}
    rounded
    borderLess={false}
  />
);

export default MenuButton;
