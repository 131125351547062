import { Button } from 'components/Button';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { AlgorandLogo } from 'icons/AlgorandLogo';
import useAlgorand, {
  WalletProviders,
} from 'modules/Algorand/providers/AlgorandProvider';
import React, { FC, HTMLAttributes, useCallback } from 'react';
import { isChrome } from 'react-device-detect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DisconnectedProps extends HTMLAttributes<HTMLDivElement> {}

export const Disconnected: FC<DisconnectedProps> = () => {
  const { connect } = useAlgorand();
  const isMobile = useCheckMobileScreen();

  const handleConnectWithAlgoSignerClick = useCallback(() => {
    connect(WalletProviders.AlgoSigner);
  }, [connect]);

  const handleConnectWithWalletConnectClick = useCallback(() => {
    connect(WalletProviders.WalletConnect);
  }, [connect]);

  const handleConnectWitMyAlgoClick = useCallback(() => {
    connect(WalletProviders.MyAlgo);
  }, [connect]);

  return (
    <>
      <AlgorandLogo />
      <div className="flex flex-col">
        <span>Connect your</span>
        <span>Algo Wallet</span>
      </div>
      <div className="medium-gap-wrapper">
        {!isMobile && isChrome && (
          <Button
            onClick={handleConnectWithAlgoSignerClick}
            upperCase
            className="w-48 h-12"
          >
            <img src="/assets/algo_signer.png" alt="Algo Signer Logo" />
          </Button>
        )}
        <Button
          onClick={handleConnectWithWalletConnectClick}
          upperCase
          className="w-48 h-12"
        >
          <img src="/assets/wallet_connect.png" alt="Wallet Connect Logo" />
        </Button>
        <Button
          onClick={handleConnectWitMyAlgoClick}
          upperCase
          className="w-48 h-12"
          withoutPadding
        >
          <img src="/assets/my_algo.png" alt="My Algo Logo" />
        </Button>
      </div>
    </>
  );
};

export default Disconnected;
