import { Button } from 'components/Button';
import { ButtonProps } from 'components/Button/Button';
import { EXHIBITIONS_ROUTE, WALLET_ROUTE } from 'constants/routes';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import useAlgorand from '../../providers/AlgorandProvider';

type WalletButtonProps = ButtonProps;

export const WalletButton: FC<WalletButtonProps> = (props) => {
  const { connected, disconnect, openConnectSide } = useAlgorand();
  const history = useHistory();

  const handleConnectClick = useCallback(
    () => openConnectSide(),
    [openConnectSide],
  );

  const handleMyWalletClick = useCallback(() => {
    history.push(WALLET_ROUTE);
  }, [history]);

  const handleDisconnectWalletClick = useCallback(async () => {
    await disconnect();
    history.push(EXHIBITIONS_ROUTE);
  }, [disconnect, history]);

  return (
    <>
      {connected ? (
        <>
          <Button {...props} onClick={handleMyWalletClick} className="mr-4">
            My wallet
          </Button>
          <Button {...props} onClick={handleDisconnectWalletClick}>
            Log out
          </Button>
        </>
      ) : (
        <Button {...props} onClick={handleConnectClick}>
          Connect Wallet
        </Button>
      )}
    </>
  );
};
