import classNames from 'classnames';
import { motion, VariantLabels, Variants } from 'framer-motion';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { useRect } from 'hooks/useRect';
import useWindowSize from 'hooks/useWindowSize';
import truncate from 'lodash/truncate';
import { FilePreview } from 'modules/global/types/File';
import { Item } from 'modules/Item/types/Item';
import React, {
  FC,
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { remToPx } from 'utils/utils';
import { Preview } from '../Preview';
import './GalleryItem.scss';

const MAX_TITLE_LENGTH = 32;
const MAX_MOBILE_TITLE_LENGTH = 16;

// NFT-560 Force to use video preview if file is a video
function selectPreviewToDisplay(item: Item): FilePreview {
  const file = item.file;
  const preview = item.preview;
  return file.isVideo && preview.isImage ? file : preview;
}

interface GalleryItemProps extends HTMLAttributes<HTMLDivElement> {
  item: Item;
  index?: number;
  onPress: (item: Item) => void;
  onEnd: () => void;
  selectedItem: Item | null;
  scroll?: number;
  dragging?: boolean;
  onMediaLoad?: (item: Item) => void;
}

export const GalleryItem: FC<GalleryItemProps> = ({
  item,
  selectedItem,
  onPress,
  onEnd,
  scroll = 0,
  onMediaLoad,
  dragging = false,
  className,
  style,
}) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [animation, setAnimation] = useState<VariantLabels>('initial');
  const imageRef = useRef<HTMLDivElement>(null);
  const imageRect = useRect(imageRef);
  const [leftOffset, setLeftOffset] = useState<number>();
  const isMobile = useCheckMobileScreen();
  const { width: windowWidth } = useWindowSize();
  const itemPreview = selectPreviewToDisplay(item);
  const itemShortTitle = useMemo(
    () => [
      truncate(item.name, {
        length: isMobile ? MAX_MOBILE_TITLE_LENGTH : MAX_TITLE_LENGTH,
        separator: ' ',
      }),
      item.file.year,
    ],
    [isMobile, item],
  );

  const onLoaded = useCallback(() => {
    setIsImgLoaded(true);
    if (item && typeof onMediaLoad === 'function') onMediaLoad(item);
  }, [item, onMediaLoad]);

  const isItemSelected = useCallback(
    () => selectedItem && item.id === selectedItem.id,
    [item.id, selectedItem],
  );

  useEffect(() => {
    if (!isItemSelected()) return;

    const galleryWrapperEl = document.getElementById('slider');
    galleryWrapperEl
      ? setLeftOffset(imageRect.left - galleryWrapperEl.scrollLeft)
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const variants: Variants = {
    initial: {
      left: 0,
      width: imageRect.width,
      height: imageRect.height,
      top: imageRect.top,
      scale: 1.05,
      y: 0,
      x: imageRect.left,
    },
    move: {
      left: 0,
      width: imageRect.width,
      height: imageRect.height,
      x: scroll + windowWidth / 2 - imageRect.width / 2,
      top: imageRect.top,
      scale: 1.05,
      y: 0,
      transition: {
        duration: 0.4,
        ease: [1, 0, 0.6, 1],
        delay: 0.3,
      },
    },
    scale: {
      left: 0,
      width: imageRect.width,
      height: imageRect.height,
      x: scroll + windowWidth / 2 - imageRect.width / 2,
      y: 0,
      top: imageRect.top,
      scale: remToPx(30) / imageRect.width,
      maxWidth: remToPx(30),
      maxHeight: remToPx(30),
      transition: {
        duration: 0.5,
        ease: [1, 0, 0.6, 1],
        delay: 0.1,
      },
    },
  };

  const animateItem = useCallback(() => {
    setAnimating(true);
    setAnimation('move');
    onPress(item);
  }, [onPress, setAnimation, setAnimating, item]);

  const handleAnimationEnd = () => {
    if (!animating) return;

    if (animation === 'move') {
      setAnimation('scale');
    }

    if (animation === 'scale') {
      onEnd();
    }
  };

  return (
    <>
      {/* Creating a hidden duplicate element to animate */}
      <motion.div
        variants={variants}
        initial="initial"
        animate={animation}
        onAnimationComplete={handleAnimationEnd}
        style={{
          left: leftOffset,
          position: 'fixed',
          opacity: animating ? '1' : '0',
          transition: 'none',
          transform: 'translateZ(0)',
          transformOrigin: '50% 50%',
        }}
        className={classNames('gallery-item-preview-wrapper', {
          loaded: isImgLoaded,
        })}
      >
        <Preview
          preview={itemPreview}
          className="pointer-events-none"
          onLoaded={onLoaded}
          fluid={false}
        />
      </motion.div>
      <div
        onClick={animateItem}
        className={classNames('gallery-item mx-5 md:mx-16', {
          className,
          selected: leftOffset && isItemSelected(),
          'fade-out': selectedItem,
          dragging: dragging,
        })}
        style={{ transform: 'translateZ(0)', ...style }}
      >
        <motion.div
          ref={imageRef}
          style={{ left: leftOffset }}
          className={classNames('gallery-item-preview-wrapper', {
            loaded: isImgLoaded,
            hide: isItemSelected(),
          })}
        >
          <Preview
            preview={itemPreview}
            className="pointer-events-none"
            onLoaded={onLoaded}
            fluid={false}
          />
        </motion.div>
        <div className="gallery-item-description mt-5 md:mt-16">
          {/* @ts-ignore */}
          <span className="gallery-item-title" title={item.title}>
            {itemShortTitle.filter(Boolean).join(', ')}
          </span>
          <span className="gallery-item-seller">{item.seller?.name}</span>
          {Boolean(item.quantity) && !item.availableQuantity && (
            <span>Sold out</span>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(GalleryItem);
