import useInitialLoad from 'hooks/useInitialLoad';
import { ArgsType, First } from 'modules/global/types/types';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useVideojs } from 'react-videojs-hook';
import styled from 'styled-components';
import 'video.js/dist/video-js.css';
import './VideoPreview.scss';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: transparent;
`;

export interface VideoPreviewProps
  extends Partial<First<ArgsType<typeof useVideojs>>> {
  src: string;
}

const VideoPreview: FC<VideoPreviewProps> = (props) => {
  const { src = '', ...videoProps } = props;
  const initialLoad = useInitialLoad();

  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src,
    controls: false,
    autoplay: true,
    responsive: true,
    loop: true,
    muted: true,
    fluid: true,
    inactivityTimeout: 0,
    bigPlayButton: false,
    ...videoProps,
  });

  const onInit = useCallback(() => {
    if (vjsRef.current && initialLoad) {
      // @ts-ignore
      vjsRef.current['muted'] = true;
    }
  }, [initialLoad, vjsRef]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <Wrapper data-vjs-player>
      <video
        ref={vjsRef}
        id={vjsId}
        className={vjsClassName}
        color="#ffffff"
        playsInline
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <p>
          Your browser does not support HTML5 video. Here is a{' '}
          <a href={src}>link to the video</a> instead.
        </p>
      </video>
    </Wrapper>
  );
};

export default memo(VideoPreview, (prev, next) => prev.src === next.src);
