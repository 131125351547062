import { ExhibitionsNavList } from 'components/ExhibitionsNavList';
import ArrowRight from 'icons/ArrowRight';
import { Footer } from 'layout/Footer';
import { Preview } from 'modules/Exhibition/components/Preview';
import React, { FC, HTMLAttributes } from 'react';
import { Curator } from '../../types/Curator';

interface CuratorInfoProps extends HTMLAttributes<HTMLDivElement> {
  curator: Curator;
}

export const CuratorInfo: FC<CuratorInfoProps> = ({ curator }) => {
  const { name, jobTitle, additionalPicture, about, currentExhibitions } =
    curator;

  return (
    <div
      data-scroll-section
      data-scroll-speed={1}
      className="sticky top-0 bg-black text-white p-5 xl:p-10 min-h-screen flex flex-col justify-between"
      style={{ zIndex: 2 }}
    >
      <div className="flex items-start justify-between mb-40">
        <div className="max-w-xs pr-2">
          <div className="flex flex-wrap items-center">
            <span className="uppercase">Information</span>
            <ArrowRight className="inline-block mx-2" />
            <span className="whitespace-nowrap">{name}</span>
          </div>
          <div>{jobTitle}</div>
        </div>
        <div className="w-52">
          <Preview preview={additionalPicture} />
        </div>
      </div>
      <div className="w-full xl:w-1/4">
        <p
          className="mt-16 prose-sm leading-5"
          dangerouslySetInnerHTML={{ __html: about }}
        />
      </div>
      <ExhibitionsNavList
        header="Curated Exhibitions"
        exhibitions={currentExhibitions}
        curatedBy={curator}
      />
      <Footer withCuratedBy={false} />
    </div>
  );
};

export default CuratorInfo;
