/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { ChevronRight } from 'icons/ChevronRight';
import { Tick } from 'icons/Tick';
import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useState,
} from 'react';

interface EmailInputProps {
  onSave: (value: string) => void;
  isSent: boolean;
  isError: boolean;
}

export const EmailInput: FC<EmailInputProps> = ({
  onSave,
  isSent,
  isError,
}) => {
  const [value, setValue] = useState('');

  const handleSave = () => onSave(value);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    if (key === 'Enter' && !isSent) {
      handleSave();
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="max-w-xl relative">
      <input
        name="extra-email"
        className={classNames(
          'w-full focus:outline-none border py-4 px-5 leading-4 text-center focus:none',
          { 'bg-transparent': !isSent },
          { 'bg-white text-green-700': isSent },
          { 'border-red-600': isError },
          { 'border-white': !isError },
        )}
        placeholder="enter email"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={isSent ? 'Sent!' : value}
      />
      {isSent && (
        <Tick className="absolute m-auto inset-y-0 right-5 text-green-700" />
      )}
      {!isSent && (
        <button className="absolute inset-y-0 right-5" onClick={handleSave}>
          <ChevronRight />
        </button>
      )}
    </div>
  );
};

export default EmailInput;
