import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement } from 'react';
import { useParams } from 'react-router';
import useExhibitionDetails from '../hooks/useExhibitionDetails';
import { ExhibitionRouteParams } from '../types/Exhibition';

const withExhibitionDetails =
  <P extends Record<string, any>>(
    WrappedComponent: ComponentType<P>,
    additionalPath?: string,
  ): FC<P> =>
  (props): ReactElement => {
    const { id } = useParams<ExhibitionRouteParams>();
    const [exhibition, error] = useExhibitionDetails({ id, additionalPath });

    return exhibition ? (
      <WrappedComponent exhibition={exhibition} {...props} />
    ) : (
      <PendingPage error={error} />
    );
  };

export default withExhibitionDetails;
