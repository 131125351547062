import React, { FC, HTMLAttributes } from 'react';

export const CaretUp: FC<HTMLAttributes<HTMLOrSVGElement>> = ({ ...props }) => (
  <svg
    width="0.5em"
    height="0.31em"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.09078 4.45459L0.547991 0.36373L7.63357 0.36373L4.09078 4.45459Z"
      fill="currentColor"
    />
  </svg>
);
