import { Button } from 'components/Button';
import { Close } from 'icons/Close';
import useAlgoSignerData from 'modules/Algorand/hooks/useAlgoSignerData';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import { useFaqModal } from 'modules/Faq/providers/FaqModalProvider';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Connected } from '../Connected';
import { Disconnected } from '../Disconnected';
import { Failed } from '../Failed';
import { InstallPlugin } from '../InstallPlugin';
import './ConnectWallet.scss';

interface ConnectWalletProps {
  setConnectSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export const ConnectWallet: FC<ConnectWalletProps> = ({
  setConnectSidebarOpen,
}) => {
  const { isAlgoSigner, connected, error } = useAlgorand();
  const { isPluginAvailable } = useAlgoSignerData();
  const { open: openFaqModal } = useFaqModal();

  const handleCloseClick = useCallback(() => {
    setConnectSidebarOpen(false);
  }, [setConnectSidebarOpen]);

  const goToFaq = useCallback(() => {
    handleCloseClick();
    openFaqModal();
  }, [handleCloseClick, openFaqModal]);

  return (
    <div
      className="flex flex-col flex-center h-full text-center py-5"
      style={{
        textTransform: 'initial',
        fontSize: 'initial',
        color: 'initial',
        lineHeight: 'initial',
      }}
    >
      <Button
        onClick={handleCloseClick}
        borderLess
        className="self-end mr-3"
        style={{ backgroundColor: 'transparent' }}
      >
        <Close color="black" />
      </Button>
      <div className="flex-grow flex flex-col flex-center bottom-gap-wrapper w-48">
        {isAlgoSigner() && !isPluginAvailable && <InstallPlugin />}
        {!connected && !error && !isAlgoSigner() && <Disconnected />}
        {connected && <Connected onClose={handleCloseClick} />}
        {error && <Failed />}
      </div>
      {!connected && (
        <div className="underline flex flex-col opacity-50 cursor-pointer">
          <span onClick={goToFaq}>What is Algo?</span>
          <span onClick={goToFaq}>Issues Connecting?</span>
        </div>
      )}
    </div>
  );
};

export default ConnectWallet;
