import { SmoothScroll } from 'components/SmoothScroll';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { CuratorInfo } from 'modules/Curator/components/CuratorInfo';
import { CuratorPreview } from 'modules/Curator/components/CuratorPreview';
import { Curator } from 'modules/Curator/types/Curator';
import React, { FC, HTMLAttributes } from 'react';

interface CuratorPageProps extends HTMLAttributes<HTMLDivElement> {
  curator: Curator;
}

export const CuratorPage: FC<CuratorPageProps> = ({ curator }) => {
  useLayoutConfig({
    HeaderProps: {
      withBackButton: true,
      fadeIn: true,
    },
  });

  return (
    <>
      <SmoothScroll>
        <CuratorPreview curator={curator} />
        <CuratorInfo curator={curator} />
      </SmoothScroll>
    </>
  );
};

export default CuratorPage;
