import { ChevronDown } from 'icons/ChevronDown';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import React, { Dispatch, FC, HTMLAttributes, SetStateAction } from 'react';
import Select, { components, NamedProps } from 'react-select';

interface WalletSelectProps extends HTMLAttributes<HTMLDivElement> {
  selectedAddress?: string;
  setSelectedAddress: Dispatch<SetStateAction<string | undefined>>;
}

export const WalletSelect: FC<WalletSelectProps> = ({
  selectedAddress,
  setSelectedAddress,
}) => {
  const { accounts } = useAlgorand();
  if (!accounts) return <></>;

  const sharedStyles = {
    color: 'white',
    font: 'VSCO Gothic',
    boxShadow: 'none',
  };
  const selectOptions: NamedProps<typeof accounts[number]> = {
    className: 'w-full',
    components: {
      DropdownIndicator: (props) => (
        <components.DropdownIndicator {...props}>
          <ChevronDown />
        </components.DropdownIndicator>
      ),
      IndicatorSeparator: () => <></>,
    },
    defaultInputValue: selectedAddress,
    getOptionLabel: ({ address }) => address,
    getOptionValue: ({ address }) => address,
    isSearchable: true,
    noOptionsMessage: () => 'No found wallet address',
    onChange: (event) => setSelectedAddress(event?.address),
    options: accounts,
    placeholder: 'Select wallet...',
    menuPlacement: 'auto',
    styles: {
      dropdownIndicator: (styles) => ({
        ...styles,
        ...sharedStyles,
        '&:hover': {
          ...sharedStyles,
        },
      }),
      placeholder: (styles) => ({ ...styles, ...sharedStyles }),
      menu: (styles) => ({
        ...styles,
        ...sharedStyles,
        borderRadius: 0,
      }),
      input: (styles) => ({ ...styles, ...sharedStyles }),
      singleValue: (styles) => ({
        ...styles,
        ...sharedStyles,
        fontSize: '0.68rem',
        '&:hover': {
          borderColor: styles.borderColor,
        },
      }),
      option: (styles, state) => ({
        ...styles,
        ...sharedStyles,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'black' : 'transparent',
        '&:hover': {
          backgroundColor: state.isSelected ? 'black' : 'lightgray',
        },
      }),
      control: (styles) => ({
        ...styles,
        ...sharedStyles,
        backgroundColor: 'transparent',
        borderRadius: 'none',
        padding: '0.625rem',
        borderColor: 'white',
        '&:hover': {
          borderColor: 'white',
        },
      }),
    },
  };
  return <Select {...selectOptions} />;
};

export default WalletSelect;
