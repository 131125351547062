import { AxiosError } from 'axios';
import { apiErrors } from 'constants/apiErrors';
import get from 'lodash/get';
import { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

export const numberWithLetters = (numb: number): string => {
  if (!Number(numb)) return '';
  return numb < 1000
    ? numb.toString()
    : numb < 1000000
    ? `${numb / 1000}K`
    : `${numb / 1000000}M`;
};

export function encodeSvg(reactElement: ReactElement): string {
  return (
    'data:image/svg+xml,' +
    escape(ReactDOMServer.renderToStaticMarkup(reactElement))
  );
}

type Matrix = {
  x: number;
  y: number;
  z: number;
};

export const getElementMatrix = (element: HTMLElement): Matrix => {
  if (!element.style.transform.length) return { x: 0, y: 0, z: 0 };

  const values = element.style.transform.split(/\w+\(|\);?/);
  const transform = values[1].split(/,\s?/g).map(parseInt);

  return {
    x: transform[0],
    y: transform[1],
    z: transform[2],
  };
};

const getBaseFontSizeValue = (): number => {
  const baseFontSize = window.getComputedStyle(document.body).fontSize;
  return Number(baseFontSize.substr(0, baseFontSize.length - 2));
};

export const pxToRem = (val: number): number => {
  return val / getBaseFontSizeValue();
};

export const remToPx = (val: number): number => {
  return val * getBaseFontSizeValue();
};

const createLinkAndDownload = (fileURL: string, name: string) => {
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = name;
  link.style.display = 'none';
  link.target = 'blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = async (
  fileURL: string,
  name: string,
): Promise<void> => {
  try {
    const blob = await new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('get', fileURL, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        xhr.readyState === 4 ? resolve(xhr.response) : reject();
      };
      xhr.onerror = () => reject();
      xhr.send();
    });
    createLinkAndDownload(window.URL.createObjectURL(blob), name);
  } catch (e) {
    console.error(e);
    createLinkAndDownload(fileURL, name);
  }
};

export const getApiErrorMessage = (error: AxiosError): string => {
  const apiMessage = get(error, 'response.data.message');
  return (
    get(apiErrors, apiMessage) || apiMessage || get(error, 'message') || error
  );
};
