import { useAppLayoutTheme } from 'layout/AppLayout/AppLayoutProvider';
import { ComponentType, FC, ReactElement, useEffect } from 'react';

const withParentRoute =
  <P extends Record<string, any>>(
    WrappedComponent: ComponentType<P>,
    parent?: string,
  ): FC<P> =>
  (props): ReactElement => {
    const { setParentRoute } = useAppLayoutTheme();

    useEffect(() => {
      setParentRoute(parent);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <WrappedComponent {...props} />;
  };

export default withParentRoute;
