import withExhibitionCover from 'modules/Exhibition/hoc/withExhibitionCover';
import withExhibitionPreviewMode from 'modules/Exhibition/hoc/withExhibitionPreviewMode';
import { ExhibitionGallery as ExhibitionGalleryComponent } from './ExhibitionGallery';

export const ExhibitionGallery = withExhibitionCover(
  ExhibitionGalleryComponent,
);
export const ExhibitionPreview = withExhibitionPreviewMode(
  ExhibitionGalleryComponent,
);
