import { AxiosError } from 'axios';
import { ITEMS_API_PATH } from 'constants/apiPaths';
import usePreviewModeToken from 'hooks/usePreviewModeToken';
import usePrivateExhibitionToken from 'hooks/usePrivateExhibitionToken';
import usePrivateItemToken from 'hooks/usePrivateItemToken';
import useUuid from 'hooks/useUuid';
import { useEffect, useState } from 'react';
import api from 'utils/api';
import { getApiErrorMessage } from 'utils/utils';
import { itemSerializer } from '../serializers/itemSerializer';
import { Item } from '../types/Item';

type useItemDetailsProps = Pick<Item, 'id'> & {
  exhibitionId?: number | string;
};

const useItemDetails = ({
  id,
  exhibitionId,
}: useItemDetailsProps): Partial<[Item, string]> => {
  const [item, setItem] = useState<Item>();
  const [error, setError] = useState<string>();
  const uuid = useUuid();
  const [previewModeToken] = usePreviewModeToken();
  const [privateExhibitionToken] = usePrivateExhibitionToken(exhibitionId);
  const [privateItemToken, storeToken] = usePrivateItemToken(id);

  useEffect(() => {
    if (!id || item || error) return;
    const getItem = async () => {
      try {
        const headers: Record<string, string> = { 'X-VISIT': uuid };
        const token =
          previewModeToken || privateExhibitionToken || privateItemToken;
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
        const response = await api.get<Item>(`${ITEMS_API_PATH}/${id}`, {
          headers,
        });
        setItem(itemSerializer(response.data) as Item);
      } catch (e) {
        const error = e as Error & AxiosError;
        setError(getApiErrorMessage(error));
      }
    };
    getItem();
  }, [
    error,
    id,
    item,
    previewModeToken,
    privateExhibitionToken,
    privateItemToken,
    uuid,
  ]);

  useEffect(() => {
    if (item || privateExhibitionToken) {
      storeToken(privateExhibitionToken);
    }
  }, [item, privateExhibitionToken, storeToken]);

  return [item, error];
};

export default useItemDetails;
