import React, { FC, HTMLAttributes } from 'react';

export const Tick: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="1.06em"
    height="0.69em"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <line
      x1="16.5133"
      y1="0.640663"
      x2="6.61385"
      y2="10.5402"
      stroke="currentColor"
    />
    <line
      x1="7.28573"
      y1="10.5401"
      x2="1.28573"
      y2="4.54008"
      stroke="currentColor"
    />
  </svg>
);
