import classNames from 'classnames';
import React, { FC } from 'react';
import './ExhibitionLogo.scss';

export const ExhibitionLogo: FC<{ animate?: boolean }> = ({ animate }) => (
  <div
    className={classNames(
      'flex-row flex-center flex-wrap uppercase small-gap-wrapper exhibition-logo',
      { 'fade-in': animate },
    )}
  >
    <span className={classNames('box', { animate: animate })}>
      <span className={classNames({ 'fade-in': animate })}>Exhibitions</span>
    </span>
    <span className={classNames({ 'fade-in': animate })}>by</span>
    <span className={classNames('box', { animate: animate })}>
      <span className={classNames({ 'fade-in': animate })}>
        PLAZA, a VSCO thing
      </span>
    </span>
  </div>
);

export default ExhibitionLogo;
