import { CURATOR_ROUTE } from 'constants/routes';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import VSCOFaceSmile from 'icons/VSCOFaceSmile';
import React, { FC, useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { diffDaysFromNow } from 'utils/format-date';
import { Exhibition } from '../../types/Exhibition';
import { Countdown } from '../Countdown';
import './ExhibitionBar.scss';

interface ExhibitionBarProps {
  exhibition: Exhibition;
  showEnterButton?: boolean;
  header?: boolean;
  onEnter?: () => void;
}

export const ExhibitionBar: FC<ExhibitionBarProps> = (props) => {
  const { exhibition, header = false, onEnter = () => null } = props;
  const {
    curator,
    name,
    verbName,
    endAt,
    startAt,
    isActive,
    isUpcoming,
    isClosed,
  } = exhibition;
  const [displayExtra, setDisplayExtra] = useState(false);
  const isMobile = useCheckMobileScreen();

  const handleCuratorClick = () =>
    generatePath(CURATOR_ROUTE, { id: curator.id });

  const CuratorLabel = () => (
    <Link className="box curator" to={handleCuratorClick}>
      <span>{curator.name}</span>
    </Link>
  );
  const NameLabel = () => (
    <div className="box gallery">
      <span>{name}</span>
    </div>
  );
  const VerbNameLabel = () => (
    <div className="box verb">
      <span>{verbName}</span>
    </div>
  );
  const DaysLeftLabel = () => (
    <div className="box days">
      <span>{diffDaysFromNow(endAt)}</span>
    </div>
  );
  const UpcomingButton = () => (
    <Countdown
      toDate={startAt}
      onEnd={onEnter}
      className="box"
      style={{ minWidth: 130 }}
    />
  );

  useEffect(() => {
    isMobile && header ? setDisplayExtra(false) : setDisplayExtra(true);
  }, [header, isMobile]);

  return (
    <div className="exhibition-bar">
      {isUpcoming && (
        <div className="flex flex-wrap sm:flex-nowrap justify-center">
          <Container>
            <CuratorLabel />
            <TextSeparator>will start</TextSeparator>
            <NameLabel />
          </Container>
          <Container>
            <TextSeparator>in</TextSeparator>
            <UpcomingButton />
          </Container>
        </div>
      )}

      {isActive && (
        <div className="flex-center flex-wrap sm:flex-nowrap">
          <Container>
            <CuratorLabel />
            <TextSeparator>is</TextSeparator>
            {!displayExtra && <VerbNameLabel />}
          </Container>
          {displayExtra && (
            <div className="exhibition-bar-container flex w-full sm:w-auto sm:block">
              <VerbNameLabel />
              <TextSeparator>for </TextSeparator>
              <DaysLeftLabel /> <TextSeparator>more days </TextSeparator>
            </div>
          )}
        </div>
      )}

      {isClosed && (
        <div className="flex flex-wrap sm:flex-nowrap justify-center">
          <Container>
            <NameLabel />
            <TextSeparator>by </TextSeparator>
            <CuratorLabel />
          </Container>
          <Container>
            <TextSeparator>is now closed</TextSeparator>
            <VSCOFaceSmile />
          </Container>
        </div>
      )}
    </div>
  );
};

const TextSeparator: FC = ({ children }) => (
  <span className="text-separator">{children}</span>
);
const Container: FC = ({ children }) => (
  <div className="exhibition-bar-container">{children}</div>
);

export default ExhibitionBar;
