import classNames from 'classnames';
import { Footer } from 'layout/Footer';
import { Header } from 'layout/Header';
import { HeaderProps as HeaderComponentProps } from 'layout/Header/Header';
import React, { FC, HTMLAttributes, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { ThemeVariants } from './AppLayoutProvider';

export interface AppLayoutProps extends HTMLAttributes<HTMLDivElement> {
  theme?: ThemeVariants;
  HeaderProps?: HeaderComponentProps;
  withoutHeader?: boolean;
  withFooter?: boolean;
}

export const AppLayout: FC<AppLayoutProps> = ({
  children,
  className,
  HeaderProps,
  withoutHeader = false,
  withFooter = false,
  ...props
}) => {
  const { pathname } = useLocation();
  const mainContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mainContainerRef.current?.scrollIntoView();
  }, [pathname]);

  return (
    <div
      ref={mainContainerRef}
      className={classNames(
        'min-h-screen flex flex-col bg-cover bg-center',
        className,
      )}
      {...props}
    >
      {!withoutHeader && <Header {...HeaderProps} />}
      {children}
      {withFooter && <Footer className="mt-auto p-4 xl:px-9" />}
    </div>
  );
};

export default AppLayout;
