import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement } from 'react';
import { useTransactionsList } from './hooks/useTransactionsList';

const withTransactionsList =
  <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const [transactions, error] = useTransactionsList();

    return transactions ? (
      <WrappedComponent transactions={transactions} {...props} />
    ) : (
      <PendingPage error={error} />
    );
  };

export default withTransactionsList;
