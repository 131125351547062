export const EXHIBITIONS_API_PATH = '/exhibitions';
export const COVER_API_PATH = '/cover';
export const FAQ_API_PATH = '/faq';
export const ITEMS_API_PATH = '/items';

export const TRANSACTIONS_API_PATH = '/transactions';
export const CREATE_PURCHASE_API_PATH = `${TRANSACTIONS_API_PATH}/purchase/create`;
export const PUBLISH_PURCHASE_API_PATH = `${TRANSACTIONS_API_PATH}/purchase/publish`;

export const WALLET_API_PATH = '/wallet';
export const WALLET_TRANSACTIONS_API_PATH = `${WALLET_API_PATH}${TRANSACTIONS_API_PATH}`;

export const CURATORS_API_PATH = '/users';

export const AUTH_API_PATH = '/auth';
export const EXHIBITION_LOGIN_API_PATH = '/login';

export const TERMS_API_PATH = '/terms';
export const PRIVACY_API_PATH = '/privacy-policy';
export const PAYMENT_RULES_API_PATH = '/payment-rules';
