import truncate from 'lodash/truncate';
import { fileSerializer } from 'modules/global/serializers/fileSerializer';
import { getYear } from 'utils/format-date';
import { numberWithLetters } from 'utils/utils';
import { Item, ItemBase } from '../types/Item';

const DEFAULT_CURRENCY = 'Algo';

export const itemSerializer = (item: ItemBase): Item => {
  const file = fileSerializer(item.file);
  const preview = fileSerializer(item.preview);
  const title = [item.name, file.year].filter(Boolean).join(', ');
  const shortTitle = truncate(title, { length: 50, separator: ' ' });
  const featuredIn = item.featuredIn?.map((featured) => ({
    ...featured,
    year: getYear(featured.startAt),
    title: shortTitle,
  }));

  return {
    ...item,
    file,
    preview,
    title,
    displayPrice: numberWithLetters(item.price),
    availableQuantity: item.quantity - item.sold,
    currency: DEFAULT_CURRENCY,
    featuredIn,
    shortTitle,
  };
};
