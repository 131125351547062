import { FC, HTMLAttributes } from 'react';

export const VSCOFaceSad: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="3.38em"
    height="3.38em"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M27 1.08C41.31 1.08 52.92 12.69 52.92 27C52.92 41.31 41.31 52.92 27 52.92C12.69 52.92 1.08 41.31 1.08 27C1.08 12.69 12.69 1.08 27 1.08ZM27 0C12.096 0 0 12.096 0 27C0 41.904 12.096 54 27 54C41.904 54 54 41.904 54 27C54 12.096 41.904 0 27 0Z"
      fill="black"
    />
    <path
      d="M33.21 46.3321H32.13C32.13 43.5241 29.808 41.2021 27 41.2021C24.192 41.2021 21.87 43.5241 21.87 46.3321H20.79C20.79 42.9301 23.598 40.1221 27 40.1221C30.402 40.1221 33.21 42.9301 33.21 46.3321Z"
      fill="black"
    />
    <path
      d="M20.4122 24.2999L19.4402 23.8139C19.7102 23.1659 19.7642 22.5179 19.4942 21.8699C19.2242 21.2219 18.7922 20.7359 18.1442 20.4119C17.2802 19.9799 16.3622 20.1419 15.6062 20.7359C15.2822 21.0059 14.9582 21.3839 14.6342 21.7079C14.2022 22.1939 13.7702 22.6799 13.2302 23.0579C12.1502 23.7599 10.9082 23.9219 9.82822 23.4359C7.99222 22.5719 7.18222 20.4119 8.04622 18.5759L9.01822 19.0079C8.74822 19.6559 8.69422 20.3039 8.96422 20.9519C9.18022 21.5999 9.66622 22.0859 10.3142 22.4099C11.0702 22.7339 11.8802 22.6799 12.6362 22.1399C13.0682 21.8159 13.4462 21.3839 13.8242 20.9519C14.1482 20.5739 14.4722 20.1959 14.9042 19.8179C15.9842 18.9539 17.2802 18.7919 18.5762 19.3319C19.4402 19.7639 20.1422 20.4659 20.4662 21.3839C20.8982 22.4099 20.8442 23.3819 20.4122 24.2999Z"
      fill="black"
    />
    <path
      d="M30.132 34.7759H21.708L28.404 15.3899L29.43 15.7679L23.22 33.6959H30.132V34.7759Z"
      fill="black"
    />
  </svg>
);

export default VSCOFaceSad;
