import { FC, HTMLAttributes } from 'react';

export const VSCOFaceSmile: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <title>VSCO_Face</title>
    <path
      d="M50,2A48,48,0,1,1,2,50,48.05,48.05,0,0,1,50,2m0-2a50,50,0,1,0,50,50A50,50,0,0,0,50,0Z"
      fill="currentColor"
    />
    <path
      d="M50,86.8A11.49,11.49,0,0,1,38.52,75.32h2a9.48,9.48,0,0,0,19,0h2A11.49,11.49,0,0,1,50,86.8Z"
      fill="currentColor"
    />
    <path
      d="M37.83,45,36,44.12a4.77,4.77,0,0,0-2.33-6.32,4.2,4.2,0,0,0-4.65.59,15.45,15.45,0,0,0-1.76,1.83,13.79,13.79,0,0,1-2.63,2.54,6.26,6.26,0,0,1-6.26.66,6.76,6.76,0,0,1-3.3-9l1.82.84a4.76,4.76,0,0,0,2.32,6.32,4.25,4.25,0,0,0,4.3-.5,12.15,12.15,0,0,0,2.24-2.19,17.21,17.21,0,0,1,2-2.07A6.18,6.18,0,0,1,34.53,36a6.77,6.77,0,0,1,3.3,9Z"
      fill="currentColor"
    />
    <polygon
      points="55.77 64.4 40.24 64.4 52.59 28.54 54.48 29.19 43.05 62.4 55.77 62.4 55.77 64.4"
      fill="currentColor"
    />
  </svg>
);

export default VSCOFaceSmile;
