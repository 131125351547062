import classNames from 'classnames';
import { Button } from 'components/Button';
import { PRIVACY_POLICY_ROUTE, TERMS_ROUTE } from 'constants/routes';
import { useFaqModal } from 'modules/Faq/providers/FaqModalProvider';
import React, { FC, HTMLAttributes } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { currentYear } from 'utils/format-date';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  withCuratedBy?: boolean;
}

export const Footer: FC<FooterProps> = ({
  withCuratedBy = true,
  className,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isOpen, close } = useFaqModal();

  const handleTermsClick = () => {
    if (isOpen) close();
    return history.push(TERMS_ROUTE);
  };
  const handlePrivacyPolicyClick = () => {
    if (isOpen) close();
    return history.push(PRIVACY_POLICY_ROUTE);
  };

  return (
    <footer
      className={classNames(
        'text-xxs sm:text-xs flex justify-between flex-wrap',
        className,
      )}
    >
      {withCuratedBy && (
        <span className="xs:flex-0 sm:flex-1 uppercase w-screen pb-6 sm:pb-0 text-center sm:text-left">
          An exhibition curated by
        </span>
      )}
      <div className="xs:flex-0 sm:flex-1 flex justify-between sm:justify-end w-screen sm:w-auto text-center">
        <Button
          borderLess={true}
          withoutPadding={true}
          variant="link"
          onClick={handleTermsClick}
          minHeight="0"
          className={classNames('mr-5 pt-0 mt-0 text-xxs sm:text-xs', {
            'pointer-events-none': pathname === TERMS_ROUTE,
          })}
          style={{ minHeight: 0 }}
        >
          Terms &amp; Conditions
        </Button>
        <Button
          borderLess={true}
          withoutPadding={true}
          variant="link"
          onClick={handlePrivacyPolicyClick}
          minHeight="0"
          className={classNames('mr-5 pt-0 mt-0 text-xxs sm:text-xs', {
            'pointer-events-none': pathname === PRIVACY_POLICY_ROUTE,
          })}
          style={{ minHeight: 0 }}
        >
          Privacy Policy
        </Button>
        <span>(C)&nbsp;{currentYear}, PLAZA</span>
      </div>
    </footer>
  );
};

export default Footer;
