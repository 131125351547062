import EmailInput from 'modules/Item/components/EmailInput/EmailInput';
import React, { FC } from 'react';

interface PhysicalCopyInputProps {
  onSave: (value: string) => void;
  onDismiss: () => void;
  isSent: boolean;
  isError: boolean;
}

export const PhysicalCopyInput: FC<PhysicalCopyInputProps> = ({
  onSave,
  onDismiss,
  isSent,
  isError,
}) => {
  return (
    <div className="flex flex-col w-full max-w-sm items-center">
      <div className="max-w-xs text-center mb-5">
        This artwork is accompanied by a physical copy. Please enter an email
        address to receive shipping details.
      </div>
      <div className="w-full mb-5">
        <EmailInput onSave={onSave} isSent={isSent} isError={isError} />
      </div>
      <div>
        <a onClick={onDismiss} className="text-xs underline cursor-pointer">
          Dismiss
        </a>
      </div>
    </div>
  );
};

export default PhysicalCopyInput;
