import { ExhibitionLogo } from 'components/ExhibitionLogo';
import { PRIVACY_API_PATH } from 'constants/apiPaths';
import useAxios, { AxiosState } from 'hooks/useAxios';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { PendingPage } from 'pages/PendingPage';
import React, { FC } from 'react';
import './PrivacyPolicyPage.scss';

type PrivacyPolicyResponse = {
  description: string;
};

export const PrivacyPolicyPage: FC = () => {
  const { response: privacyPolicy, isError: error } = usePrivacyPolicyContent();

  useLayoutConfig({
    HeaderProps: {
      withBackButton: true,
      text: <ExhibitionLogo />,
    },
    withFooter: true,
  });

  return privacyPolicy ? (
    <div className="policy">
      <article
        className="policy-article w-full text-sm p-5 pt-60 md:p-36"
        dangerouslySetInnerHTML={{ __html: privacyPolicy.description }}
      />
    </div>
  ) : (
    <PendingPage error={error} />
  );
};

const usePrivacyPolicyContent = (): AxiosState<PrivacyPolicyResponse> =>
  useAxios<PrivacyPolicyResponse>(PRIVACY_API_PATH);

export default PrivacyPolicyPage;
