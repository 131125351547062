/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { AxiosResponse } from 'axios';
import {
  CREATE_PURCHASE_API_PATH,
  PUBLISH_PURCHASE_API_PATH,
} from 'constants/apiPaths';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import {
  prepareSignedTransactionToPurchase,
  prepareTransactionToSign,
} from 'modules/Algorand/utils/Algorand';
import api from 'utils/api';
import { Transaction } from '../types/Transaction';

export type CreatePurchaseParams = {
  itemId: string | number;
  quantity: number;
  address: string;
};

export type CreatePurchaseResponse = {
  group: number[][];
  signed: number[];
};

export type PublishPurchaseParams = {
  transactions: number[][];
};

export type PublishPurchaseResponse = AxiosResponse<Transaction>;

const useTransactionService = (): {
  purchaseTransaction: (params: CreatePurchaseParams) => Promise<Transaction>;
} => {
  const { signTxn } = useAlgorand();

  async function createPurchase(payload: CreatePurchaseParams): Promise<any> {
    return api.post<CreatePurchaseParams, any>(
      CREATE_PURCHASE_API_PATH,
      payload,
    );
  }

  async function signTransaction(
    data: CreatePurchaseResponse,
  ): Promise<number[][]> {
    const signedTransactions = await signTxn(
      prepareTransactionToSign(data.group),
    );
    return prepareSignedTransactionToPurchase(signedTransactions, data.signed);
  }

  async function publishPurchase(
    transactions: number[][],
  ): Promise<PublishPurchaseResponse> {
    return api.post<PublishPurchaseParams, PublishPurchaseResponse>(
      PUBLISH_PURCHASE_API_PATH,
      {
        transactions,
      },
    );
  }

  async function purchaseTransaction(
    params: CreatePurchaseParams,
  ): Promise<Transaction> {
    const response = await createPurchase(params);
    const signedTransactions = await signTransaction(response.data);
    const published = await publishPurchase(signedTransactions);
    return published.data;
  }

  return { purchaseTransaction };
};

export default useTransactionService;
