import React, { FC, HTMLAttributes } from 'react';
export const Cross: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="1.62em"
    height="1.62em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <line x1="12.5" y1="25.728" x2="12.5" y2="0.272184" stroke="white" />
    <line x1="0.272156" y1="12.5" x2="25.728" y2="12.5" stroke="white" />
  </svg>
);

export default Cross;
