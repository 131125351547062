import jwt from 'jwt-decode';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

type StoreTokenCallback = (token: string) => void;
type RemoveTokenCallback = () => void;
type PrivateExhibitionToken = Record<'exhibitionId' | 'iat' | 'exp', number>;

function usePrivateExhibitionToken(
  exhibitionId?: string | number,
): [string, StoreTokenCallback, RemoveTokenCallback] {
  const tokenStoreKey = `vsco-exhibition-${exhibitionId}`;
  const [cookie, setCookie, removeCookie] = useCookies([tokenStoreKey]);
  const storedToken: string = cookie[tokenStoreKey];

  const storeToken = useCallback<StoreTokenCallback>(
    (token) => {
      const decodedToken = jwt<PrivateExhibitionToken>(token);
      setCookie(tokenStoreKey, token, {
        expires: new Date(decodedToken.exp * 1000),
      });
    },
    [setCookie, tokenStoreKey],
  );

  const removeToken = useCallback<RemoveTokenCallback>(() => {
    removeCookie(tokenStoreKey);
  }, [removeCookie, tokenStoreKey]);

  return [storedToken, storeToken, removeToken];
}

export default usePrivateExhibitionToken;
