import React, { FC, HTMLAttributes } from 'react';

export const ArrowDown: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  ...props
}) => (
  <svg
    width="1.25em"
    height="1.75em"
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="9.97412"
      y1="0.313477"
      x2="9.97412"
      y2="26.3135"
      stroke="currentColor"
    />
    <line
      x1="19.5635"
      y1="17.1412"
      x2="9.66402"
      y2="27.0406"
      stroke="currentColor"
    />
    <line
      x1="10.3359"
      y1="27.0406"
      x2="0.436406"
      y2="17.1411"
      stroke="currentColor"
    />
  </svg>
);

export default ArrowDown;
