import { itemSerializer } from 'modules/Item/serializers/itemSerializer';
import { Item } from 'modules/Item/types/Item';
import moment from 'moment';
import { Transaction, TransactionBase } from '../types/Transaction';

const SHORT_DATE_FORMAT = 'DD MM YYYY';

export const transactionSerializer = (
  transaction: TransactionBase,
): Transaction => ({
  ...transaction,
  displayCreatedAt: moment(transaction.createdAt).format(SHORT_DATE_FORMAT),
  item: itemSerializer(transaction.item) as Item,
});
