import { AxiosError } from 'axios';
import { EXHIBITIONS_API_PATH } from 'constants/apiPaths';
import { EXHIBITION_ROUTE } from 'constants/routes';
import usePreviewModeToken from 'hooks/usePreviewModeToken';
import usePrivateExhibitionToken from 'hooks/usePrivateExhibitionToken';
import useUuid from 'hooks/useUuid';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import api from 'utils/api';
import { getApiErrorMessage } from 'utils/utils';
import { exhibitionSerializer } from '../serializers/exhibitionSerializer';
import {
  Exhibition,
  ExhibitionResponse,
  ExtendedExhibition,
} from '../types/Exhibition';

const useExhibitionDetails = ({
  id,
  additionalPath = '',
}: Pick<Exhibition, 'id'> & {
  additionalPath?: string;
}): Partial<[ExtendedExhibition, string]> => {
  const [exhibition, setExhibition] = useState<ExtendedExhibition>();
  const [error, setError] = useState<string>();
  const history = useHistory();
  const [previewModeToken] = usePreviewModeToken();
  const [privateExhibitionToken, , removePrivateExhibitionToken] =
    usePrivateExhibitionToken(id);
  const uuid = useUuid();

  const redirectToExhibitionCover = useCallback(() => {
    history.replace(generatePath(EXHIBITION_ROUTE, { id }));
  }, [history, id]);

  useEffect(() => {
    if (!id || exhibition) return;
    const getExhibition = async () => {
      try {
        const headers: Record<string, string> = { 'X-VISIT': uuid };
        const token = previewModeToken || privateExhibitionToken;
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
        const response = await api.get<ExhibitionResponse>(
          `${EXHIBITIONS_API_PATH}/${id}${additionalPath}`,
          { headers },
        );
        setExhibition(exhibitionSerializer(response.data));
      } catch (e) {
        const error = e as Error & AxiosError;
        if (error.response?.status === 403) {
          redirectToExhibitionCover();
        } else {
          setError(getApiErrorMessage(error));
        }
      }
    };
    getExhibition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibition]);

  useEffect(() => {
    if (exhibition && privateExhibitionToken && !exhibition.hasPassword) {
      removePrivateExhibitionToken();
    }
  }, [exhibition, privateExhibitionToken, removePrivateExhibitionToken]);

  return [exhibition, error];
};

export default useExhibitionDetails;
