import { Button } from 'components/Button';
import { ExhibitionsNavList } from 'components/ExhibitionsNavList';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import useQuery from 'hooks/useQuery';
import ArrowLeft from 'icons/ArrowLeft';
import { Footer } from 'layout/Footer';
import filter from 'lodash/filter';
import { useExhibitionsList } from 'modules/Exhibition/hooks/useExhibitionsList';
import { ExhibitionStatus } from 'modules/Exhibition/types/Exhibition';
import { useFaqContent } from 'modules/Faq/hooks/useFaqContent';
import { useFaqModal } from 'modules/Faq/providers/FaqModalProvider';
import { faqContentSerializer } from 'modules/Faq/serializers/faqSerializer';
import { FAQContent } from 'modules/Faq/types/Faq';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FaqArticle } from './components/FaqArticle';
import { FaqRouter } from './components/FaqRouter';
import { FaqKeys } from './constants/FaqKeys.enum';
import './FaqPage.scss';

export const FaqPage: FC = () => {
  const [activeExhibitions, setActiveExhibitions] = useState<any[]>([]);
  const [exhibitions] = useExhibitionsList();
  const [faqContent, setFaqContent] = useState<FAQContent>([]);
  const [tabs, setTabs] = useState<any[]>([]);
  const { close } = useFaqModal();
  const { hash } = useLocation();
  const { response: faqResponse } = useFaqContent();
  const isMobile = useCheckMobileScreen();
  const query = useQuery();

  useEffect(() => {
    if (exhibitions) {
      const active = filter(exhibitions, { status: ExhibitionStatus.Active });
      setActiveExhibitions(active);
    }
  }, [exhibitions]);

  useEffect(() => {
    if (faqResponse) setFaqContent(faqContentSerializer(faqResponse));
  }, [faqResponse]);

  useEffect(() => {
    const questions = faqContent?.map((element) => ({
      ...element,
      component: (
        <FaqArticle
          key={element.key}
          faqElement={element}
          initialOpen={query.get(FaqKeys.UrlParam) === element.key}
        />
      ),
      article: () => (
        <p dangerouslySetInnerHTML={{ __html: element?.answer || '' }} />
      ),
    }));
    setTabs(questions || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqContent, hash]);

  return (
    <div className="bg-black text-white p-5 xl:p-10">
      <Button
        borderLess
        withoutPadding
        onClick={close}
        className="sticky top-10 self-start back-button"
      >
        <ArrowLeft className="transition-transform" />
      </Button>
      <h1
        className="text-center uppercase mt-28"
        style={{ fontSize: '10vw', lineHeight: '10vw' }}
      >
        Overview
      </h1>
      <div>
        <div className="h-full">
          {isMobile ? (
            <div className="my-14 border-t">
              {tabs.map((tab) => tab.component)}
            </div>
          ) : (
            <FaqRouter tabs={tabs} />
          )}
        </div>
        <ExhibitionsNavList
          header="Live exhibitions"
          exhibitions={activeExhibitions}
        />
        <Footer withCuratedBy={false} />
      </div>
    </div>
  );
};

export default FaqPage;
