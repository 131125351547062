import React, { FC, HTMLAttributes } from 'react';

export const ArrowRight: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  ...props
}) => (
  <svg
    width="2.75em"
    height="1.31em"
    viewBox="0 0 44 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="0.302246"
      y1="10.6826"
      x2="42.3022"
      y2="10.6826"
      stroke="currentColor"
    />
    <line
      x1="33.1509"
      y1="1.08492"
      x2="43.0504"
      y2="10.9844"
      stroke="currentColor"
    />
    <line
      x1="43.0503"
      y1="10.3125"
      x2="33.1508"
      y2="20.212"
      stroke="currentColor"
    />
  </svg>
);

export default ArrowRight;
