import Breakpoint from 'constants/Breakpoint.enum';
import { useEffect, useState } from 'react';

const useCheckMobileScreen = (): boolean => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= Breakpoint.Mobile;
};

export default useCheckMobileScreen;
