import classNames from 'classnames';
import { Button } from 'components/Button';
import { ButtonProps as ButtonComponentProps } from 'components/Button/Button';
import { MenuButton } from 'components/MenuButton';
import { EXHIBITIONS_ROUTE } from 'constants/routes';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { usePreviewMode } from 'hooks/usePreviewMode';
import useWindowSize from 'hooks/useWindowSize';
import { ArrowLeft } from 'icons/ArrowLeft';
import {
  ThemeVariants,
  useAppLayoutTheme,
} from 'layout/AppLayout/AppLayoutProvider';
import { WalletButton } from 'modules/Algorand/components/WalletButton';
import { useFaqModal } from 'modules/Faq/providers/FaqModalProvider';
import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { generatePath, useHistory, useLocation } from 'react-router';
import './Header.scss';

export interface HeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  ButtonProps?: ButtonComponentProps;
  text?: string | FC | JSX.Element;
  withBackButton?: boolean;
  customLeftButton?: React.ReactNode;
  fadeOut?: boolean;
  fadeIn?: boolean;
}

export const Header: FC<HeaderProps> = ({
  ButtonProps,
  className,
  text,
  withBackButton,
  customLeftButton,
  fadeOut,
  fadeIn,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useCheckMobileScreen();
  const { width } = useWindowSize();
  const history = useHistory();
  const { pathname, search, state } = useLocation();
  const { appLayoutProps, parentRoute, parentRouteParams } =
    useAppLayoutTheme();
  const { open: openFaqModal } = useFaqModal();
  const [isPreviewMode] = usePreviewMode();

  const headerSideItemWidth = useMemo(
    () => (isMobile ? '2.625rem' : '10.625rem'),
    [isMobile],
  );

  const onBackClick = useCallback(() => {
    return parentRoute
      ? history.push(generatePath(parentRoute, parentRouteParams), state)
      : history.goBack();
  }, [history, parentRoute, parentRouteParams, state]);

  const onExhibitionsClick = useCallback(() => {
    return pathname !== EXHIBITIONS_ROUTE && history.push(EXHIBITIONS_ROUTE);
  }, [pathname, history]);

  const onFaqClick = useCallback(() => {
    openFaqModal();
  }, [openFaqModal]);

  useEffect(() => {
    setMenuOpen(false);
  }, [width, pathname, search]);

  const DEFAULT_BUTTON_PROPS: ButtonComponentProps = {
    upperCase: true,
    borderLess: true,
    withoutPadding: true,
    variant: 'link',
  };
  ButtonProps = {
    ...DEFAULT_BUTTON_PROPS,
    ...ButtonProps,
  };

  const mobileMenuButtons = () => {
    const gradient =
      appLayoutProps?.theme === ThemeVariants.Black
        ? 'from-black via-black text-white'
        : 'from-white via-white text-black';
    return (
      <div
        className={classNames(
          'absolute top-0 inset-x-0 flex flex-col items-start text-2xl h-1/2-screen bg-gradient-to-b -mt-2 p-5',
          gradient,
        )}
      >
        <Button {...ButtonProps} onClick={onExhibitionsClick}>
          Exhibitions
        </Button>
        <Button {...ButtonProps} onClick={onFaqClick}>
          Info
        </Button>
        <WalletButton disabled={isPreviewMode} {...ButtonProps} />
      </div>
    );
  };

  return (
    <header
      className={classNames(
        'header absolute top-0 w-full z-10 text-xs xl:text-xxs',
        { 'menu-open': menuOpen },
        { 'fade-out': fadeOut },
        { 'fade-in': fadeIn },
        className,
      )}
      {...props}
    >
      <div className="relative flex justify-between items-start sm:items-center w-full px-4 sm:px-9 py-5 pb-0">
        <div style={{ width: headerSideItemWidth }}>
          {withBackButton && (
            <Button
              onClick={onBackClick}
              {...ButtonProps}
              variant="button"
              className="back-button"
            >
              <ArrowLeft className="transition-transform" />
            </Button>
          )}
          {customLeftButton}
        </div>
        <div className="mx-4 overflow-x-hidden">{text}</div>
        {isMobile ? (
          <MenuButton
            style={{ zIndex: 1 }}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            {...ButtonProps}
          />
        ) : (
          <div
            className="right-5 flex justify-end"
            style={{ width: headerSideItemWidth }}
          >
            <Button onClick={onFaqClick} className="mr-5" {...ButtonProps}>
              Info
            </Button>
            <WalletButton disabled={isPreviewMode} {...ButtonProps} />
          </div>
        )}
        {isMobile && menuOpen && mobileMenuButtons()}
      </div>
    </header>
  );
};

export default Header;
