import React, { FC, HTMLAttributes } from 'react';

export const ChevronUp: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="1.38em"
    height="0.75em"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M0.999773 11.0928L10.9658 1.07959L20.9204 11.0928"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </svg>
);
