import { TRANSACTIONS_API_PATH } from 'constants/apiPaths';
import { TRANSACTION_ROUTE } from 'constants/routes';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { useCallback, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import api from 'utils/api';
import { Transaction } from '../types/Transaction';

interface PhysicalCopyInterface {
  isSent: boolean;
  isError: boolean;
  isMobile: boolean;
  onEnterClick: () => void;
  onSaveEmail: (value: string) => void;
}

export const usePhysicalCopy = ({
  transaction,
}: {
  transaction: Transaction;
}): PhysicalCopyInterface => {
  const isMobile = useCheckMobileScreen();
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  const onEnterClick = useCallback(
    () =>
      history.push(
        generatePath(TRANSACTION_ROUTE, {
          publicWalletAddress: transaction.publicWalletAddress,
          transactionId: transaction.id,
        }),
      ),
    [history, transaction],
  );

  const onSaveEmail = useCallback(
    async (email: string) => {
      const updateEmail = async (email: string, address: string) => {
        try {
          const { status } = await api.put(
            `${TRANSACTIONS_API_PATH}/${transaction.id}/update-email`,
            { address, email },
          );

          return status === 200;
        } catch (e) {
          return false;
        }
      };

      if (/^[^@\s]+@[a-z0-9.]+\.[a-z0-9]{2,8}$/i.test(email)) {
        const sent = await updateEmail(email, transaction.publicWalletAddress);
        setIsError(!sent);
        setIsSent(sent);
      } else {
        setIsError(true);
      }
    },
    [transaction],
  );

  return {
    isSent,
    isError,
    isMobile,
    onEnterClick,
    onSaveEmail,
  };
};
