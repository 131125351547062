import {
  SerializedSignedTransaction,
  SignedTransaction,
  WalletTransaction,
} from '../types/Algorand';

function base64ToByteArray(blob: string): Uint8Array {
  return stringToByteArray(atob(blob));
}

function byteArrayToBase64(array: any): string {
  return btoa(byteArrayToString(array));
}

function stringToByteArray(str: string): Uint8Array {
  return new Uint8Array(str.split('').map((x) => x.charCodeAt(0)));
}

function byteArrayToString(array: any): string {
  return String.fromCharCode.apply(null, array);
}

const transformTransactionToAlgorandObject = (
  transaction: string,
  signers?: Array<string>,
): WalletTransaction => {
  const txn: WalletTransaction = { txn: transaction };
  if (Array.isArray(signers)) {
    (txn as any).signers = signers;
  }
  return txn;
};

export const prepareTransactionToSign = (
  group: number[][],
): WalletTransaction[] => {
  const base64Txs = group.map(byteArrayToBase64);
  return [
    ...base64Txs
      .slice(0, base64Txs.length - 1)
      .map((t) => transformTransactionToAlgorandObject(t)),
    transformTransactionToAlgorandObject(base64Txs[base64Txs.length - 1], []),
  ];
};

function isUint8ArrayType(array: any): boolean {
  return (
    typeof array === 'object' &&
    !!Object.prototype.toString.call(array).match(/Array/gm)
  );
}

function hasObjectBlobProperty(obj: any): boolean {
  return (
    typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, 'blob')
  );
}

function isSignedTransactionType(txn: any): txn is SignedTransaction | string {
  return (
    txn !== null &&
    (typeof txn === 'string' ||
      isUint8ArrayType(txn) ||
      hasObjectBlobProperty(txn))
  );
}

function isSerializedSignedTransactionType(
  txn: any,
): txn is SerializedSignedTransaction {
  return (
    txn !== null && hasObjectBlobProperty(txn) && isUint8ArrayType(txn.blob)
  );
}

function serializeSignedTransactions(
  transactions: any[],
): SerializedSignedTransaction[] {
  return transactions.reduce((acc: SerializedSignedTransaction[], txn: any) => {
    if (!txn) return acc;
    if (isSerializedSignedTransactionType(txn)) return [...acc, txn];
    if (!isSignedTransactionType(txn)) return acc;

    if (typeof txn === 'string') {
      return [...acc, { blob: base64ToByteArray(txn) }];
    }
    if (isUint8ArrayType(txn)) {
      return [...acc, { blob: txn }];
    }
    if (typeof txn.blob === 'string') {
      return [...acc, { ...txn, blob: base64ToByteArray(txn.blob) }];
    }

    return acc;
  }, []);
}

export const prepareSignedTransactionToPurchase = (
  signedTransactions: any[],
  signed: number[],
): number[][] => {
  const serializedTransactions = serializeSignedTransactions(
    signedTransactions,
  ).map((txn) => Array.from(txn.blob));

  return [...serializedTransactions, signed];
};
