import { Button } from 'components/Button';
import { VscoSpinner } from 'components/VscoSpinner';
import { DEFAULT_ROUTE } from 'constants/routes';
import { AnimatePresence, motion } from 'framer-motion';
import VSCOFaceSad from 'icons/VSCOFaceSad';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';

interface PendingPageProps {
  error?: string | boolean;
}

export const PendingPage: FC<PendingPageProps> = ({ error = false }) => {
  const history = useHistory();

  const handleGoHomeClick = useCallback(
    () => history.push(DEFAULT_ROUTE),
    [history],
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.4,
        }}
        className="flex items-center justify-center m-auto"
      >
        {error ? (
          <div className="h-full flex-center flex-col large-gap-wrapper">
            <VSCOFaceSad />
            <span>{error}</span>
            <Button onClick={handleGoHomeClick}>Go to home page</Button>
          </div>
        ) : (
          <VscoSpinner />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default PendingPage;
