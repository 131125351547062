import React, { CSSProperties, FC } from 'react';
import './PreviewWrapper.scss';

type PreviewWrapperProps = Pick<CSSProperties, 'width' | 'height'>;

export const PreviewWrapper: FC<PreviewWrapperProps> = ({
  width,
  height,
  children,
}) => {
  return (
    <div
      className="preview-wrapper"
      style={{ width: width || height, height: height || width }}
    >
      {children}
    </div>
  );
};

export default PreviewWrapper;
