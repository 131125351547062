import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, useHistory } from 'react-router';
import { ExhibitionLogo } from '../../components/ExhibitionLogo';
import { ScrollListener, SmoothScroll } from '../../components/SmoothScroll';
import { VscoSpinner } from '../../components/VscoSpinner';
import { EXHIBITION_ROUTE } from '../../constants/routes';
import useCheckAnimations from '../../hooks/useCheckAnimations';
import useInitialLoad from '../../hooks/useInitialLoad';
import useLayoutConfig from '../../layout/AppLayout/useLayoutConfig';
import { Footer } from '../../layout/Footer';
import { Header } from '../../layout/Header';
import { ExhibitionListItem } from '../../modules/Exhibition/components/ExhibitionListItem';
import { ExhibitionSummary } from '../../modules/Exhibition/types/Exhibition';
import './ExhibitionList.scss';

const container = {
  loading: {
    opacity: 0,
    y: 5,
  },
  loaded: {
    y: 0,
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      duration: 0.6,
      ease: [0.3, 0.7, 0.2, 0.7],
    },
  },
};

const child = {
  loading: {
    opacity: 0,
    y: 5,
  },
  loaded: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: [0.3, 0.7, 0.2, 0.7],
    },
  },
};

function getFooterHeight(): string {
  const footer = document.querySelector('footer');
  const height = footer?.getBoundingClientRect().height || 0;
  return `${height}px`;
}

interface ExhibitionsListProps {
  exhibitions: ExhibitionSummary[];
  introComplete?: boolean;
}

export const ExhibitionsList: FC<ExhibitionsListProps> = ({
  exhibitions,
  introComplete = false,
}) => {
  const history = useHistory();
  const [selectedExhibition, setSelectedExhibition] =
    useState<ExhibitionSummary>();
  const loadedExhibitions: ExhibitionSummary[] = [];
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);
  const [scrollBy, setScrollBy] = useState(0);
  const exhibitionsWithMedia = exhibitions.filter(
    (exhibition) => exhibition.preview.isImage || exhibition.preview.isVideo,
  );
  const animationsSupported = useCheckAnimations();
  const initialLoad = useInitialLoad();

  useLayoutConfig({
    withoutHeader: true,
    withFooter: false,
  });

  const goToExhibition = useCallback(
    (exhibition: ExhibitionSummary) =>
      history.push(
        generatePath(EXHIBITION_ROUTE, { id: exhibition.id }),
        exhibition,
      ),
    [history],
  );

  const handleExhibitionClick = useCallback(
    (exhibition: ExhibitionSummary) => {
      setSelectedExhibition(exhibition);
      if (!animationsSupported) setAnimationEnded(true);
    },
    [animationsSupported],
  );

  const handleAnimationEnd = useCallback(() => {
    setAnimationEnded(true);
  }, []);

  const onExhibitionLoad = (exhibition: ExhibitionSummary) => {
    loadedExhibitions.push(exhibition);
    if (loadedExhibitions.length >= exhibitionsWithMedia.length) {
      setMediaLoaded(true);
    }
  };

  useEffect(() => {
    if (animationEnded && selectedExhibition) {
      goToExhibition(selectedExhibition);
    }
  }, [selectedExhibition, animationEnded, history, goToExhibition]);

  useEffect(() => {
    if (!exhibitions.length) setMediaLoaded(true);
  }, [exhibitions.length]);

  return (
    <SmoothScroll>
      <ScrollListener
        paused={Boolean(selectedExhibition)}
        onScroll={(val) => setScrollBy(val)}
      />
      <Helmet>
        {exhibitions.map((exhibition) => (
          <link
            key={exhibition.id}
            rel="preload"
            href={exhibition.preview.path}
            as={exhibition.preview.isVideo ? 'video' : 'image'}
          />
        ))}
      </Helmet>
      <div
        className="h-full"
        style={{ userSelect: 'none' }}
        data-scroll-section
      >
        {introComplete && (
          <Header
            withBackButton={false}
            text={<ExhibitionLogo />}
            fadeOut={Boolean(selectedExhibition)}
            fadeIn={!selectedExhibition && !initialLoad}
          />
        )}
        <div
          className="exhibition-list px-4 md:px-10 xl:px-32"
          style={{ minHeight: `calc(100vh - ${getFooterHeight()})` }}
        >
          {introComplete && !mediaLoaded && (
            <div className="loading">
              <VscoSpinner />
            </div>
          )}
          <motion.div
            variants={container}
            initial="loading"
            animate={mediaLoaded && introComplete ? 'loaded' : 'loading'}
            className="table table-auto w-full pt-32 xl:pt-32"
            style={{
              borderSpacing: '0 2rem',
            }}
          >
            {exhibitions.map((exhibition) => (
              <motion.div
                variants={child}
                style={{ transform: 'translateZ(0)' }}
                key={`exhibition-${exhibition.id}`}
              >
                <ExhibitionListItem
                  className={classNames('cursor-pointer', {
                    'fade-out': animationsSupported && selectedExhibition,
                  })}
                  exhibition={exhibition}
                  handleClick={handleExhibitionClick}
                  onMediaLoad={onExhibitionLoad}
                  scroll={scrollBy}
                  onEnd={handleAnimationEnd}
                />
              </motion.div>
            ))}
          </motion.div>
        </div>
        <Footer className="mt-auto p-4 xl:px-9" />
      </div>
    </SmoothScroll>
  );
};

export default ExhibitionsList;
