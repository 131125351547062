import { ITEM_ROUTE } from 'constants/routes';
import useInitialLoad from 'hooks/useInitialLoad';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import useParentRouteParams from 'modules/global/hoc/useParentRouteParams';
import { ItemDetails } from 'modules/Item/components/ItemDetails';
import withItemDetails from 'modules/Item/hoc/withItemDetails';
import { useItemSlugInURL } from 'modules/Item/hooks/useItemSlugInURL';
import { Item } from 'modules/Item/types/Item';
import React, { FC } from 'react';

type ItemPageProps = {
  item: Item;
};

export const ItemDetailsPage: FC<ItemPageProps> = ({ item }) => {
  const isInitialLoad = useInitialLoad();

  useParentRouteParams({ id: item.exhibitionId });
  useLayoutConfig({
    HeaderProps: {
      withBackButton: true,
      fadeIn: true,
    },
    withFooter: false,
  });
  useItemSlugInURL(item, ITEM_ROUTE);

  return <ItemDetails initialLoad={isInitialLoad} fadeIn={true} item={item} />;
};

export default withItemDetails(ItemDetailsPage);
