import React, { FC, HTMLAttributes } from 'react';

export const ChevronRight: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="0.69em"
    height="1.31em"
    viewBox="0 0 11 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <line
      x1="0.353645"
      y1="0.672814"
      x2="10.2531"
      y2="10.5723"
      stroke="currentColor"
    />
    <line
      x1="10.253"
      y1="9.90043"
      x2="0.353503"
      y2="19.7999"
      stroke="currentColor"
    />
  </svg>
);
