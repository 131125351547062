import { Button } from 'components/Button';
import { PAYMENT_RULES_API_PATH } from 'constants/apiPaths';
import useAxios, { AxiosState } from 'hooks/useAxios';
import { PendingPage } from 'pages/PendingPage';
import React, { FC, useState } from 'react';
import './PaymentRulesAgreement.scss';

type PaymentRulesResponse = {
  description: string;
};

interface PaymentRulesAgreementProps {
  onConfirm: () => void;
}

export const PaymentRulesAgreement: FC<PaymentRulesAgreementProps> = ({
  onConfirm,
}) => {
  const { response: rules, isError: error } = usePaymentRulesContent();
  const [confirmed, setConfirmed] = useState(false);

  const handleAgreeClick = () => {
    setConfirmed(true);
    onConfirm();
  };

  return (
    <div className="payment-rules">
      <div className="payment-rules-title">
        VSCO Exhibitions – VSCO NFT Rules Between Artists + Owners
      </div>
      {rules ? (
        <div
          className="payment-rules-content"
          dangerouslySetInnerHTML={{ __html: rules.description }}
        />
      ) : (
        <PendingPage error={error} />
      )}

      <Button
        rounded
        disabled={confirmed}
        onClick={handleAgreeClick}
        withoutPadding
        className="px-10"
      >
        I Agree
      </Button>
    </div>
  );
};

const usePaymentRulesContent = (): AxiosState<PaymentRulesResponse> =>
  useAxios<PaymentRulesResponse>(PAYMENT_RULES_API_PATH);

export default PaymentRulesAgreement;
