import classNames from 'classnames';
import Breakpoint from 'constants/Breakpoint.enum';
import { motion, VariantLabels, Variants } from 'framer-motion';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { useRect } from 'hooks/useRect';
import useWindowSize from 'hooks/useWindowSize';
import { Dot } from 'icons/Dot';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { exhibitionDate } from 'utils/format-date';
import useImageRatio from '../../hooks/useImageRatio';
import { ExhibitionSummary } from '../../types/Exhibition';
import { Preview } from '../Preview';
import { VideoPreview } from '../VideoPreview';

const LARGE_IMG_WIDTH = 16;
const SMALL_IMG_WIDTH = 10;
const INITIAL_RECT = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
};
type RectResult = typeof INITIAL_RECT;

interface ExhibitionListItemProps {
  exhibition: ExhibitionSummary;
  onEnd: () => void;
  handleClick: (exhibition: ExhibitionSummary) => void;
  onMediaLoad?: (exhibition: ExhibitionSummary) => void;
  scroll?: number;
  className: string;
}

export const ExhibitionListItem: FC<ExhibitionListItemProps> = (props) => {
  const { onEnd, handleClick, onMediaLoad, scroll = 0, className } = props;
  const { exhibition } = props;
  const { curator, name, preview } = exhibition;
  const [scrollY, setScrollY] = useState(0);
  const [position, setPosition] = useState<RectResult>(INITIAL_RECT);
  const [animating, setAnimating] = useState(false);
  const [animation, setAnimation] = useState<VariantLabels>('initial');
  const imageRef = useRef<HTMLDivElement>(null);
  const imageRect = useRect(imageRef);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { ratio, aspectRatio, loaded } = useImageRatio(
    preview.path,
    Boolean(preview.isVideo),
  );
  const isMobile = useCheckMobileScreen();

  const imgDimensions = useMemo(() => {
    const width =
      windowWidth >= Breakpoint.LargeDesktop
        ? LARGE_IMG_WIDTH
        : SMALL_IMG_WIDTH;
    return {
      width: `${width}rem`,
      height: `${width / ratio}rem`,
    };
  }, [ratio, windowWidth]);

  const variants: Variants = useMemo(
    () => ({
      initial: {
        top: 0,
        y: scrollY + position.top,
        left: position.left,
        width: position.width,
        height: position.height,
        z: 0,
      },
      move: {
        top: 0,
        y: scrollY + windowHeight / 2 - position.height / 2,
        left: windowWidth / 2 - position.width / 2,
        width: position.width,
        height: position.height,
        z: 0,
        transition: {
          duration: 0.75,
          ease: [1, 0, 0.2, 1],
          delay: 0.5,
        },
      },
      scale: {
        top: 0,
        y: scroll,
        left: 0,
        width: windowWidth,
        height: windowHeight,
        z: 0,
        transition: {
          duration: 0.5,
          ease: [1, 0, 0.2, 1],
          delay: 0.2,
        },
      },
    }),
    [position, scroll, scrollY, windowHeight, windowWidth],
  );

  const animateItem = useCallback(() => {
    handleClick(exhibition);
    const rect = imageRef.current?.getBoundingClientRect();
    setPosition(rect || INITIAL_RECT);
    setAnimating(true);
    setScrollY(scroll);
    setAnimation('move');
  }, [setAnimation, setAnimating, handleClick, exhibition, scroll]);

  const handleAnimationEnd = useCallback(() => {
    switch (animation) {
      case 'move':
        setAnimation('scale');
        break;
      case 'scale':
        onEnd();
        break;

      default:
        break;
    }
  }, [animation, onEnd]);

  const AnimatedPreview = useMemo(
    () => (
      <motion.div
        variants={variants}
        initial="initial"
        animate={animation}
        onAnimationComplete={handleAnimationEnd}
        className={classNames(
          'bg-cover bg-center flex flex-col text-white transform-gpu',
          { 'bg-skeleton': !preview.isVideo },
        )}
        style={{
          position: 'fixed',
          backgroundImage: `url('${preview.path}')`,
          aspectRatio,
          zIndex: 50,
          transform: 'translateZ(0)',
        }}
      >
        {preview.isVideo && (
          <Preview preview={preview} className="flex flex-center" />
        )}
      </motion.div>
    ),
    [animation, aspectRatio, handleAnimationEnd, preview, variants],
  );

  useEffect(() => {
    if (loaded && typeof onMediaLoad === 'function') onMediaLoad(exhibition);
  }, [exhibition, loaded, onMediaLoad]);

  return (
    <>
      {animating && AnimatedPreview}
      <div
        className={classNames(
          'table-row align-middle xl:align-top cursor-pointer transform-gpu',
          className,
        )}
        onClick={animateItem}
      >
        <div className="table-cell align-middle xl:align-top py-2 xl:py-0 ">
          {preview.isVideo ? (
            <div
              ref={imageRef}
              className="flex flex-center"
              style={{
                ...imgDimensions,
                height: 'auto',
                top: `${imageRect.top}px`,
                left: `${imageRect.left}px`,
                transform: 'translateZ(0)',
              }}
            >
              <VideoPreview src={preview.path} />
            </div>
          ) : (
            <div
              ref={imageRef}
              className="bg-cover bg-center bg-skeleton flex flex-col text-white"
              style={{
                backgroundImage: `url('${preview.path}')`,
                aspectRatio,
                ...imgDimensions,
                top: `${imageRect.top}px`,
                left: `${imageRect.left}px`,
                transform: 'translateZ(0)',
              }}
            />
          )}
        </div>

        <div className="table-cell pl-10 md:pr-10 xl:px-32 align-middle xl:align-top w-full description">
          <span className="text-lg xl:text-3.5xl xl:leading-8 block font-semibold md:mb-3">
            {curator.name}
          </span>
          <span className="text-lg xl:text-2xl xl:leading-6 block">{name}</span>
          {isMobile && <DateColumn exhibition={exhibition} />}
        </div>

        {!isMobile && (
          <div className="table-cell align-middle xl:align-top description">
            <DateColumn exhibition={exhibition} />
          </div>
        )}
      </div>
    </>
  );
};

const DateColumn = (props: Pick<ExhibitionListItemProps, 'exhibition'>) => {
  const { startAt, endAt, isActive, isUpcoming, isClosed } = props.exhibition;
  return (
    <div className="whitespace-nowrap text-xxs xl:text-base leading-7">
      <span className="inline xl:block">{exhibitionDate(startAt, endAt)} </span>
      {isUpcoming && <span>Upcoming</span>}
      {isActive && (
        <span className="text-red-500 align-middle whitespace-nowrap">
          Open
          <Dot className="inline mx-1 animate animate-blink" />
        </span>
      )}
      {isClosed && <span>Closed</span>}
    </div>
  );
};

export default ExhibitionListItem;
