import classNames from 'classnames';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { CaretUp } from 'icons/CaretUp';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Exhibition } from '../../types/Exhibition';

interface ExhibitionDetailsProps {
  exhibition: Exhibition;
  onDetailsClick?: () => void;
}

export const ExhibitionDetails: FC<ExhibitionDetailsProps> = ({
  exhibition,
  onDetailsClick,
}) => {
  const isMobile = useCheckMobileScreen();
  const infoRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [areDetailsVisible, setAreDetailsVisible] = useState(false);

  const toggleExhibitionDetails = useCallback(() => {
    if (isMobile) setAreDetailsVisible(!areDetailsVisible);
  }, [areDetailsVisible, isMobile]);

  useEffect(() => {
    setAreDetailsVisible(!isMobile);
  }, [isMobile]);

  return (
    <>
      <div
        onClick={onDetailsClick}
        ref={infoRef}
        className={classNames(
          'absolute bottom-0 max-w-md px-6 py-1 transition-all mb-0 xl:mb-4',
          {
            'right-0': !isMobile,
            'left-0': isMobile,
          },
        )}
      >
        <div
          ref={headerRef}
          className="flex items-center cursor-pointer select-none py-2"
        >
          <CaretUp
            className={classNames('inline-block mr-2 transform xl:hidden', {
              'rotate-180': !areDetailsVisible,
            })}
            onClick={(event) => {
              toggleExhibitionDetails();
              event.stopPropagation();
            }}
          />
          <span className="uppercase mr-10">Curated by</span>
          {exhibition.curator.name}
        </div>
        {areDetailsVisible && (
          <>
            <p className="overflow-y-auto xl:pb-4 mt-6">
              {exhibition.description}
            </p>
            {isMobile && (
              <div className="my-3">
                <span className="uppercase mr-2">Image by</span>
                {exhibition.backgroundImage.authorName}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ExhibitionDetails;
