import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement } from 'react';
import { useParams } from 'react-router';
import useExhibitionDetails from '../hooks/useExhibitionDetails';
import { Exhibition } from '../types/Exhibition';

const withExhibitionPreviewMode =
  <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const [exhibition, error] = useExhibitionDetails({ id });

    return (
      <>
        {exhibition ? (
          <WrappedComponent exhibition={exhibition as Exhibition} {...props} />
        ) : (
          <PendingPage error={error} />
        )}
      </>
    );
  };

export default withExhibitionPreviewMode;
