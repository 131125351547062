import moment, { unitOfTime } from 'moment';

export default function formatDate(
  toDate: string,
  targetFormatMask: string,
  withDays = true,
): [number, string] {
  const countdownMillisecond = Math.max(0, getDelta(toDate, 'milliseconds'));
  const countdownDays = Math.max(0, getDelta(toDate, 'days'));
  const duration = moment.duration(countdownMillisecond, 'milliseconds');

  const countdownString = moment
    .utc(duration.as('milliseconds'))
    .format(targetFormatMask);

  return [
    countdownMillisecond,
    withDays ? `${countdownDays}:${countdownString}` : countdownString,
  ];
}

export function getDelta(
  toDate: string,
  unitOfTime: unitOfTime.Diff,
  sourceFormat?: string,
): number {
  if (!moment.isMoment(toDate)) {
    const convert = moment.isDate(toDate)
      ? moment(toDate)
      : moment(toDate, sourceFormat);
    return convert.diff(moment(), unitOfTime);
  }

  return toDate.diff(moment(), unitOfTime);
}

export const getYear = (date: string): string => {
  return moment(date).isValid() ? moment(date).format('YYYY') : '';
};

export const exhibitionDate = (fromDate: string, toDate: string): string => {
  const from = moment(fromDate);
  const to = moment(toDate);
  const isSameMonth = from.isSame(to, 'month');
  return `${from.format('MMM D')} - ${to.format(
    `${!isSameMonth ? 'MMM' : ''} D`,
  )}`;
};

export const currentYear = moment().format('YYYY');

export const diffDaysFromNow = (date?: string): number =>
  moment(date).diff(moment(), 'days');
