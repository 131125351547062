import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { AppLayout } from '.';
import { AppLayoutProps } from './AppLayout';

export enum ThemeVariants {
  Black = 'bg-black',
  Transparent = 'bg-transparent',
}

const useAppLayoutProps = () => {
  const [appLayoutProps, setAppLayoutProps] = useState<AppLayoutProps>();
  const [parentRoute, setParentRoute] = useState<string>();
  const [parentRouteParams, setParentRouteParams] =
    useState<Record<string, string | number>>();

  const cleanUpLayout = useCallback(() => {
    setAppLayoutProps(undefined);
    setParentRoute(undefined);
    setParentRouteParams(undefined);
  }, []);

  return {
    appLayoutProps,
    setAppLayoutProps,
    cleanUpLayout,
    parentRoute,
    setParentRoute,
    parentRouteParams,
    setParentRouteParams,
  };
};

type AppLayoutThemeContextValue = ReturnType<typeof useAppLayoutProps>;

const AppLayoutThemeContext = createContext<
  AppLayoutThemeContextValue | undefined
>(undefined);

const AppLayoutThemeProvider: FC = (props) => {
  const { children } = props;
  const value = useAppLayoutProps();

  return (
    <AppLayoutThemeContext.Provider value={value}>
      <AppLayout {...value.appLayoutProps}>{children}</AppLayout>
    </AppLayoutThemeContext.Provider>
  );
};

export const useAppLayoutTheme = (): AppLayoutThemeContextValue => {
  const ctx = useContext(AppLayoutThemeContext);

  if (!ctx) {
    throw new Error('Component beyond AppLayoutThemeContext!');
  }

  return ctx;
};

export default AppLayoutThemeProvider;
