export enum apiErrors {
  ADDRESS_IS_INVALID = 'This wallet is wrong.',
  ITEM_NOT_FOUND = 'Item not found',
  EXHIBITION_NOT_FOUND = 'Exhibition not found',
  NOT_ENOUGH_FUNDS = `You don’t have enough funds to do this action.`,
  NOT_ENOUGH_ITEMS = 'Exhibition does not have items.',
  TRANSACTION_NOT_FOUND = 'We have a problem to find this transaction.',
  TOKEN_IS_INVALID = 'The token is invalid or has expired.',
  SEND_TRANSACTION_FAILED = 'Could not sign the transaction. Please try again later',
}
