import { formatJsonRpcRequest } from '@json-rpc-tools/utils';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from 'algorand-walletconnect-qrcode-modal';
import { useEffect, useState } from 'react';
import { WalletTransaction } from '../types/Algorand';

const WALLET_CONNECT_BRIDGE_URL = 'https://bridge.walletconnect.org';

interface useWalletConnectDataParams {
  accounts?: string[];
  connect: () => Promise<void | WalletConnect>;
  connected: boolean;
  connector?: WalletConnect;
  disconnect: () => Promise<void>;
  signTxn: (transactions: WalletTransaction[]) => Promise<any>;
}

const useWalletConnectData = (): useWalletConnectDataParams => {
  const [connector, setConnector] = useState<WalletConnect>();
  const [accounts, setAccounts] = useState<string[]>();
  const [connected, setConnected] = useState(false);

  const connect = async () => {
    const connector = new WalletConnect({
      bridge: WALLET_CONNECT_BRIDGE_URL,
      qrcodeModal: QRCodeModal,
    });
    if (!connector.connected) {
      await connector.createSession();
    }
    setConnector(connector);
    return connector;
  };

  const onConnect = (payload: any) => {
    const { accounts } = payload.params[0];
    setConnected(true);
    setAccounts(accounts);
  };

  const onDisconnect = () => {
    setConnected(false);
    setAccounts(undefined);
  };

  const subscribeToEvents = () => {
    if (!connector) return;

    connector.on('session_update', async (error, payload) => {
      if (error) throw error;

      const { accounts } = payload.params[0];
      setAccounts(accounts);
    });

    connector.on('connect', (error, payload) => {
      if (error) throw error;

      onConnect(payload);
    });

    connector.on('disconnect', (error) => {
      if (error) throw error;

      onDisconnect();
    });

    if (connector.connected) {
      setConnected(true);
      setAccounts(connector.accounts);
    }
  };

  const signTxn = async (
    transactions: WalletTransaction[],
  ): Promise<(number[] | null)[]> => {
    if (!connector) throw Error('WalletConnect is not initialized');
    const request = formatJsonRpcRequest('algo_signTxn', [transactions]);
    return connector.sendCustomRequest(request);
  };

  const disconnect = async () => connector?.killSession();

  useEffect(() => {
    if (connector) {
      subscribeToEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector]);

  return { connected, connector, accounts, connect, signTxn, disconnect };
};

export default useWalletConnectData;
