import { useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { Exhibition, ExhibitionRouteParams } from '../types/Exhibition';

/**Fix url with current slug of exhibition
 *
 * @param exhibition - Exhibition object
 * @param redirectPath - Route where should be redirect with current slug
 *
 * @returns Exhibition slug
 */
export const useExhibitionSlugInURL = (
  exhibition: Exhibition,
  redirectPath: string,
): string | undefined => {
  const { id, slug } = useParams<ExhibitionRouteParams>();
  const history = useHistory();

  useEffect(() => {
    if (!exhibition) return;
    if (!slug || (exhibition.slug && slug !== exhibition.slug)) {
      return history.replace(
        generatePath(redirectPath, { id, slug: exhibition.slug }),
      );
    }
  }, [exhibition, history, id, redirectPath, slug]);

  return slug;
};
