import {
  AUTH_API_PATH,
  EXHIBITIONS_API_PATH,
  EXHIBITION_LOGIN_API_PATH,
} from 'constants/apiPaths';
import usePrivateExhibitionToken from 'hooks/usePrivateExhibitionToken';
import { useCallback, useState } from 'react';
import api from 'utils/api';

interface ExhibitionPasswordInterface {
  isError: boolean;
  onSavePassword: (value: string) => void;
}

type UseExhibitionPasswordParams = {
  exhibitionId: number | string;
  onSuccess: (token: string) => void;
};

export const useExhibitionPassword = ({
  exhibitionId,
  onSuccess,
}: UseExhibitionPasswordParams): ExhibitionPasswordInterface => {
  const [isError, setIsError] = useState(false);
  const [, storeToken] = usePrivateExhibitionToken(exhibitionId);

  const onSavePassword = useCallback(
    async (password: string) => {
      try {
        setIsError(false);
        const { data: token } = await api.post(
          `${AUTH_API_PATH}${EXHIBITIONS_API_PATH}/${exhibitionId}${EXHIBITION_LOGIN_API_PATH}`,
          { password },
        );
        storeToken(token);
        return onSuccess(token);
      } catch (e) {
        setIsError(true);
      }
    },
    [exhibitionId, onSuccess, storeToken],
  );

  return {
    isError,
    onSavePassword,
  };
};
