import classNames from 'classnames';
import { Button } from 'components/Button';
import { FileInfo } from 'components/FileInfo';
import {
  CURATOR_ROUTE,
  EXHIBITIONS_ROUTE,
  EXHIBITION_GALLERY_ROUTE,
  EXHIBITION_ROUTE,
} from 'constants/routes';
import { motion } from 'framer-motion';
import useCheckAnimations from 'hooks/useCheckAnimations';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import useInitialLoad from 'hooks/useInitialLoad';
import usePrivateExhibitionToken from 'hooks/usePrivateExhibitionToken';
import { ArrowLeft } from 'icons/ArrowLeft';
import ArrowRight from 'icons/ArrowRight';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import { ExhibitionBar } from 'modules/Exhibition/components/ExhibitionBar';
import { ExhibitionDetails } from 'modules/Exhibition/components/ExhibitionDetails';
import { PasswordInput } from 'modules/Exhibition/components/PasswordInput';
import { useExhibitionSlugInURL } from 'modules/Exhibition/hooks/useExhibitionSlugInURL';
import { Exhibition } from 'modules/Exhibition/types/Exhibition';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import './ExhibitionCover.scss';
import variants from './ExhibitionCover.variants';
import { useExhibitionPassword } from './hooks/useExhibitionPassword';

interface ExhibitionCoverProps {
  exhibition: Exhibition;
}

export const ExhibitionCover: FC<ExhibitionCoverProps> = ({ exhibition }) => {
  const history = useHistory();
  const isMobile = useCheckMobileScreen();
  const isInitialLoad = useInitialLoad();
  const animationsSupported = useCheckAnimations();
  const [isRouting, setIsRouting] = useState(false);
  const [privateExhibitionToken] = usePrivateExhibitionToken(exhibition.id);
  useExhibitionSlugInURL(exhibition, EXHIBITION_ROUTE);

  const onEnterClick = useCallback(() => {
    setIsRouting(true);
    setTimeout(() => {
      history.push(
        generatePath(EXHIBITION_GALLERY_ROUTE, { id: exhibition.id }),
      );
    }, 500);
  }, [exhibition, history]);

  const onDetailsClick = useCallback(() => {
    setIsRouting(true);
    setTimeout(() => {
      history.push(generatePath(CURATOR_ROUTE, { id: exhibition.curator.id }));
    }, 500);
  }, [history, exhibition]);

  const onExhibitionsClick = useCallback(() => {
    setIsRouting(true);
    setTimeout(() => {
      history.push(EXHIBITIONS_ROUTE);
    }, 500);
  }, [history]);

  const { isError, onSavePassword } = useExhibitionPassword({
    exhibitionId: exhibition.id,
    onSuccess: onEnterClick,
  });

  const canGoBack = !isInitialLoad;

  const generateBackgroundImageUrl = (exhibition: Exhibition): string => {
    const { backgroundImage, preview } = exhibition;
    const url = backgroundImage.isVideo ? preview.path : backgroundImage.path;
    return `url('${url}')`;
  };

  const backgroundImageUrl = useMemo(
    () => (exhibition ? generateBackgroundImageUrl(exhibition) : 'none'),
    [exhibition],
  );

  useLayoutConfig({
    withoutHeader: true,
    className: 'overflow-hidden',
  });

  const backgroundVideo = useMemo(
    () => (
      <div className="fixed inset-0 w-screen -z-1 flex-center exhibition-video-preview">
        <video
          src={exhibition.backgroundImage.path}
          playsInline
          autoPlay
          muted
          loop
        />
      </div>
    ),
    [exhibition.backgroundImage.path],
  );

  const enterButton = useMemo(
    () => (
      <div className="mt-10 sm:mt-16 xl:mt-0 ml-2">
        <Button
          bold={true}
          className="enter-button w-28"
          minHeight="2.625rem"
          onClick={onEnterClick}
          rounded
          upperCase={true}
          withoutPadding={true}
        >
          <ArrowRight className="transition-transform" />
        </Button>
      </div>
    ),
    [onEnterClick],
  );

  if (!exhibition) return null;

  const { isActive, hasPassword, backgroundImage } = exhibition;

  return (
    <>
      <motion.div
        variants={variants}
        initial="initial"
        animate={isRouting ? 'out' : 'initial'}
        transition={{
          duration: 0.5,
        }}
      >
        <Button
          className="back-button fixed top-0 left-0 z-20 pl-6 pt-8 text-white fade-in"
          borderLess={true}
          upperCase={true}
          withoutPadding={true}
          variant={canGoBack ? 'button' : 'link'}
          onClick={onExhibitionsClick}
        >
          {canGoBack ? (
            <ArrowLeft color="white" className="transition-transform" />
          ) : (
            <span className="text-xxs flex-center pt-3">All Exhibitions</span>
          )}
        </Button>
        <div
          className={classNames(
            'exhibition-cover flex-center px-5 text-xxs leading-3',
            { animate: animationsSupported },
          )}
          style={{ backgroundImage: backgroundImageUrl }}
        >
          {backgroundImage.isVideo && backgroundVideo}
          <div className="flex flex-col flex-center xl:flex-row xl:text-base w-11/12">
            <ExhibitionBar
              exhibition={exhibition}
              showEnterButton
              onEnter={onEnterClick}
            />

            {isActive && (
              <>
                {!hasPassword && enterButton}
                {hasPassword && privateExhibitionToken && enterButton}
                {hasPassword && !privateExhibitionToken && (
                  <PasswordInput isError={isError} onSave={onSavePassword} />
                )}
              </>
            )}
          </div>
          <div className="absolute xl:fixed bottom-0 inset-x-0 flex justify-between items-end px-5 fade-in">
            {!isMobile && (
              <FileInfo
                file={backgroundImage}
                inline
                className="mb-4 max-w-1/2"
              />
            )}
            <ExhibitionDetails
              exhibition={exhibition}
              onDetailsClick={onDetailsClick}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ExhibitionCover;
