import { AxiosError } from 'axios';
import { WALLET_TRANSACTIONS_API_PATH } from 'constants/apiPaths';
import map from 'lodash/map';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import { ResponseList } from 'modules/global/types/types';
import { transactionSerializer } from 'modules/Transaction/serializers/transactionSerializer';
import { Transaction } from 'modules/Transaction/types/Transaction';
import { useEffect, useState } from 'react';
import api from 'utils/api';
import { getApiErrorMessage } from 'utils/utils';

export const useTransactionsList = (): [
  Transaction[] | undefined,
  string | undefined,
] => {
  const [transactions, setTransactions] = useState<Transaction[]>();
  const [error, setError] = useState<string>();
  const { accounts } = useAlgorand();

  useEffect(() => {
    const getTransactions = async () => {
      if (!accounts || !accounts.length) {
        return setError('Please connect wallet.');
      }
      try {
        const { data } = await api.get<ResponseList<Transaction>>(
          `${WALLET_TRANSACTIONS_API_PATH}`,
          { params: { addresses: map(accounts, 'address') } },
        );
        setTransactions(map(data.items, transactionSerializer));
      } catch (e) {
        const error = e as Error & AxiosError;
        setError(getApiErrorMessage(error));
      }
    };
    getTransactions();
  }, [accounts]);

  return [transactions, error];
};
