import classNames from 'classnames';
import useQuery from 'hooks/useQuery';
import find from 'lodash/find';
import first from 'lodash/first';
import { FAQElement } from 'modules/Faq/types/Faq';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaqKeys } from '../../constants/FaqKeys.enum';
import './FaqRouter.scss';

type FaqArticleTab = FAQElement & { article: FC };

interface FaqRouterProps {
  tabs: FaqArticleTab[];
}

export const FaqRouter: FC<FaqRouterProps> = ({ tabs }) => {
  const { search } = useLocation();
  const query = useQuery();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<FaqArticleTab>();

  useEffect(() => {
    if (tabs) {
      const initialArticleKey = query.get(FaqKeys.UrlParam) || first(tabs)?.key;
      const initialTab = find(tabs, { key: initialArticleKey });
      setSelectedTab(initialTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  useEffect(() => {
    if (selectedTab) {
      const queryParams = new URLSearchParams(search);
      queryParams.set(FaqKeys.UrlParam, selectedTab.key);
      history.replace({ search: queryParams.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectedTab]);

  return (
    <div className="faq-router my-14 md:my-40 md:flex justify-between">
      <div className="hidden md:block md:w-72">
        <nav className="sticky top-24">
          <ul>
            {tabs.map((tab) => (
              <li
                key={tab.key}
                className={classNames(
                  'faq-title py-5 uppercase cursor-pointer',
                  { selected: tab.key === selectedTab?.key },
                )}
                onClick={() => setSelectedTab(tab)}
              >
                {tab.question}
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="w-full md:w-1/2 faq-article text-white">
        {selectedTab ? (
          <selectedTab.article />
        ) : (
          <span>
            Sorry. The question not found. Please select another one from menu.
          </span>
        )}
      </div>
    </div>
  );
};

export default FaqRouter;
