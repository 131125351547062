/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { ChevronRight } from 'icons/ChevronRight';
import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useEffect,
  useState,
} from 'react';
import './PasswordInput.styles.scss';

interface PasswordInputProps {
  onSave: (value: string) => void;
  isError: boolean;
}

export const PasswordInput: FC<PasswordInputProps> = ({ onSave, isError }) => {
  const [value, setValue] = useState('');

  const handleSave = () => onSave(value);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    if (key === 'Enter') {
      handleSave();
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
  };

  const clearInput = () => {
    setValue('');
  };

  useEffect(() => {
    if (isError) {
      clearInput();
    }
  }, [isError]);

  return (
    <div className="password-input-wrapper animate animate-fade-in">
      <div className="password-input">
        <input
          type="password"
          className={classNames(
            'focus:outline-none border py-2 px-5 leading-4 text-center focus:none bg-transparent rounded-full font-semibold w-full',
            isError ? 'border-red-600' : 'border-white',
          )}
          placeholder="enter password"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={value}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-2 px-2"
          onClick={handleSave}
        >
          <ChevronRight />
        </button>
      </div>
      {isError && (
        <span className="absolute mt-2 text-red-600 w-full text-center">
          wrong password
        </span>
      )}
    </div>
  );
};

export default PasswordInput;
