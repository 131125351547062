import { CheckoutPage } from 'pages/CheckoutPage';
import { CuratorPage } from 'pages/CuratorPage';
import { ExhibitionCover } from 'pages/ExhibitionCover';
import { ExhibitionGallery, ExhibitionPreview } from 'pages/ExhibitionGallery';
import { ExhibitionsList } from 'pages/ExhibitionsList';
import { ItemDetailsPage } from 'pages/ItemDetailsPage';
import { PrivacyPolicyPage } from 'pages/PrivacyPolicyPage';
import { RewardPage } from 'pages/RewardPage';
import { TermsPage } from 'pages/TermsPage';
import { TransactionCoverPage } from 'pages/TransactionCoverPage';
import { WalletPage } from 'pages/WalletPage';
import { FC } from 'react';

export const PREVIEW_ROUTE = '/preview';
export const EXHIBITIONS_ROUTE = '/exhibitions';
export const EXHIBITION_ROUTE = `${EXHIBITIONS_ROUTE}/:id/:slug?`;
export const EXHIBITION_PREVIEW_ROUTE = `${EXHIBITION_ROUTE}${PREVIEW_ROUTE}`;
export const EXHIBITION_GALLERY_ROUTE = `${EXHIBITION_ROUTE}/gallery`;
export const FAQ_ROUTE = '/faq';
export const ITEMS_ROUTE = '/items';
export const ITEM_ROUTE = `${ITEMS_ROUTE}/:id/:slug?`;
export const ITEM_PREVIEW_ROUTE = `${ITEM_ROUTE}${PREVIEW_ROUTE}`;
export const ITEM_CHECKOUT_ROUTE = `${ITEM_ROUTE}/checkout`;
export const WALLET_ROUTE = '/wallet';
export const TRANSACTIONS_ROUTE = '/transactions';
export const TRANSACTION_ROUTE = `${WALLET_ROUTE}/:publicWalletAddress${TRANSACTIONS_ROUTE}/:transactionId`;
export const CURATOR_ROUTE = `/curators/:id`;
export const TERMS_ROUTE = '/terms';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const TRANSACTION_COVER_ROUTE = `${WALLET_ROUTE}/:publicWalletAddress${TRANSACTIONS_ROUTE}/:transactionId/cover`;

export const DEFAULT_ROUTE = EXHIBITIONS_ROUTE;

export const ROUTE_LIST: {
  path: string;
  component: FC<any>;
  parent?: string;
}[] = [
  {
    path: EXHIBITION_PREVIEW_ROUTE,
    component: ExhibitionPreview,
    parent: DEFAULT_ROUTE,
  },
  {
    path: EXHIBITION_GALLERY_ROUTE,
    component: ExhibitionGallery,
    parent: EXHIBITION_ROUTE,
  },
  {
    path: EXHIBITION_ROUTE,
    component: ExhibitionCover,
    parent: EXHIBITIONS_ROUTE,
  },
  {
    path: EXHIBITIONS_ROUTE,
    component: ExhibitionsList,
    parent: DEFAULT_ROUTE,
  },
  {
    path: ITEM_PREVIEW_ROUTE,
    component: ItemDetailsPage,
    parent: EXHIBITION_ROUTE,
  },
  { path: ITEM_CHECKOUT_ROUTE, component: CheckoutPage, parent: ITEM_ROUTE },
  {
    path: ITEM_ROUTE,
    component: ItemDetailsPage,
    parent: EXHIBITION_GALLERY_ROUTE,
  },
  {
    path: TRANSACTION_COVER_ROUTE,
    component: TransactionCoverPage,
    parent: WALLET_ROUTE,
  },
  { path: TRANSACTION_ROUTE, component: RewardPage, parent: WALLET_ROUTE },
  { path: WALLET_ROUTE, component: WalletPage, parent: DEFAULT_ROUTE },
  { path: CURATOR_ROUTE, component: CuratorPage, parent: EXHIBITIONS_ROUTE },
  { path: TERMS_ROUTE, component: TermsPage },
  { path: PRIVACY_POLICY_ROUTE, component: PrivacyPolicyPage },
];
