import classNames from 'classnames';
import { ExhibitionsNavList } from 'components/ExhibitionsNavList';
import { FileInfo } from 'components/FileInfo';
import { ScrollListener, SmoothScroll } from 'components/SmoothScroll';
import { ITEM_CHECKOUT_ROUTE } from 'constants/routes';
import { motion } from 'framer-motion';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import useRestoreScroll from 'hooks/useRestoreScroll';
import { Footer } from 'layout/Footer';
import { Preview } from 'modules/Exhibition/components/Preview';
import { Transaction } from 'modules/Transaction/types/Transaction';
import React, { FC, useCallback, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { Item } from '../../types/Item';
import { DownloadButtons } from '../DownloadButtons';
import { PurchaseButtons } from '../PurchaseButtons';
import './ItemDetails.scss';
import variants from './ItemDetails.variants';

interface ItemDetailsProps {
  item: Item;
  transaction?: Transaction;
  fadeIn?: boolean;
  initialLoad?: boolean;
}

export const ItemDetails: FC<ItemDetailsProps> = ({
  item,
  transaction,
  fadeIn,
  initialLoad,
}) => {
  const [showButtons, setShowButtons] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRouting, setIsRouting] = useState(false);
  const featuredInRef = useRef<HTMLDivElement>(null);
  const isMobile = useCheckMobileScreen();
  const history = useHistory();
  const buttonsClassName = 'bg-white max-w-full overflow-hidden';

  useRestoreScroll('gallery-scroll');

  const isFeaturedInInViewport = useCallback((): boolean => {
    if (!featuredInRef.current) return false;
    const top = featuredInRef.current.getBoundingClientRect().top;
    return top >= 0 && top <= window.innerHeight;
  }, []);

  const handleScroll = useCallback(() => {
    setShowButtons(!isFeaturedInInViewport());
  }, [isFeaturedInInViewport]);

  const goToCheckout = useCallback(
    () =>
      history.push(generatePath(ITEM_CHECKOUT_ROUTE, { id: item.id }), {
        item,
      }),
    [history, item],
  );

  const handleBuyClick = useCallback(() => {
    setIsRouting(true);
    setTimeout(() => {
      goToCheckout();
    }, 400);
  }, [goToCheckout]);

  const handleOnLoaded = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <SmoothScroll>
        <ScrollListener onScroll={handleScroll} />
        <motion.div
          variants={variants}
          initial="initial"
          animate={isRouting ? 'out' : 'initial'}
          transition={{
            duration: 0.4,
          }}
        >
          <div className="item-details" id="sticky">
            <div className="absolute top-0 left-0" id="sticky" />
            <div className="flex flex-col items-center justify-center h-screen">
              <div
                className={classNames('image-wrapper flex-center z-10', {
                  'fade-in': fadeIn && initialLoad && isLoaded,
                  'opacity-0': initialLoad && !isLoaded,
                })}
              >
                <Preview
                  onLoaded={handleOnLoaded}
                  preview={item.file}
                  controls
                  muted={false}
                  fluid={false}
                />
              </div>
              <div
                className={classNames(
                  'file-info md:absolute p-5 pt-16 md:p-8',
                  { 'fade-in': fadeIn },
                )}
              >
                <FileInfo
                  name={item.title}
                  author={item.seller?.name}
                  center={isMobile}
                />
              </div>
            </div>
            <div className="p-5 xl:p-10">
              <div className="flex justify-center mb-20">
                <div className="description">
                  <span className="uppercase">{item.seller?.name}</span>
                  <p
                    className="mt-16 prose-sm leading-5 "
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
              <div ref={featuredInRef}>
                <ExhibitionsNavList
                  header="Featured In"
                  exhibitions={item.featuredIn}
                  withPreviewTitle
                />
              </div>
            </div>
          </div>
          <Footer className="mt-auto p-4 xl:px-9" />
        </motion.div>
      </SmoothScroll>
      <motion.div
        variants={variants}
        initial="initial"
        animate={isRouting ? 'out' : 'initial'}
        transition={{ duration: 0.4 }}
      >
        <div
          className={classNames(
            'buttons-container w-screen md:w-auto transition-opacity p-2 medium-gap-wrapper',
            { visible: showButtons },
          )}
        >
          {transaction ? (
            <DownloadButtons
              transaction={transaction}
              buttonProps={{ className: buttonsClassName }}
            />
          ) : (
            <PurchaseButtons
              handleClick={handleBuyClick}
              item={item}
              buttonProps={{ className: buttonsClassName }}
            />
          )}
        </div>
      </motion.div>
    </>
  );
};

export default ItemDetails;
