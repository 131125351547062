import { MenuButton } from 'components/MenuButton';
import React, { FC } from 'react';
import './VscoSpinner.scss';

const VscoSpinner: FC = () => {
  return (
    <MenuButton
      style={{ zIndex: 1 }}
      menuOpen={false}
      setMenuOpen={() => null}
      className="animate-loader"
    />
  );
};

export default VscoSpinner;
