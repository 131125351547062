import { Button } from 'components/Button';
import { WALLET_ROUTE } from 'constants/routes';
import VSCO from 'icons/VSCO';
import React, { FC, HTMLAttributes, useCallback } from 'react';
import { useHistory } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ConnectedProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
}

export const Connected: FC<ConnectedProps> = ({ onClose }) => {
  const history = useHistory();

  const handleViewWalletClick = useCallback(() => {
    onClose();
    history.push(WALLET_ROUTE);
  }, [onClose, history]);

  return (
    <>
      <VSCO className="animate-spin-slow" />
      <div className="flex flex-col">
        <span>Hi!</span>
        <span>Your Algorand Wallet is now connected</span>
      </div>
      <Button onClick={handleViewWalletClick} upperCase>
        View wallet
      </Button>
    </>
  );
};

export default Connected;
