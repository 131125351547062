import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { isChrome } from 'react-device-detect';
import { Account, WalletTransaction } from '../types/Algorand';
import { Ledger } from '../types/AlgoSigner';

const DEFAULT_LEDGER = process.env.REACT_APP_DEFAULT_LEDGER;

interface useAlgoSignerDataParams {
  accounts?: Account[];
  connect: () => Promise<Error | undefined>;
  connected: boolean;
  disconnect: () => Promise<void>;
  isPluginAvailable: boolean;
  signTxn: (transactions: WalletTransaction[]) => Promise<any>;
}

const useAlgoSignerData = (): useAlgoSignerDataParams => {
  const [accounts, setAccounts] = useState<Account[]>();
  const [connected, setConnected] = useState(false);
  const [isPluginAvailable] = useState(isChrome && !isEmpty(window.AlgoSigner));

  const connect = async () => {
    if (!isPluginAvailable) return new Error('Plugin not installed');
    await window.AlgoSigner.connect();
    setConnected(true);
    const walletAccounts = await getAccounts();
    setAccounts(walletAccounts);
  };

  const disconnect = async () => setConnected(false);

  const getAccounts = async () =>
    window.AlgoSigner.accounts({
      ledger: DEFAULT_LEDGER as Ledger,
    });

  const signTxn = async (transactions: WalletTransaction[]) => {
    return window.AlgoSigner.signTxn(transactions);
  };

  return {
    connected,
    accounts,
    connect,
    disconnect,
    signTxn,
    isPluginAvailable,
  };
};

export default useAlgoSignerData;
