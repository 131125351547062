import React, { FC, HTMLAttributes } from 'react';

export const ArrowLeft: FC<HTMLAttributes<HTMLOrSVGElement>> = ({
  ...props
}) => (
  <svg
    width="2.75em"
    height="1.31em"
    viewBox="0 0 44 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="43.895"
      y1="10.4658"
      x2="1.89502"
      y2="10.4658"
      stroke="currentColor"
    />
    <line
      x1="11.0463"
      y1="20.0635"
      x2="1.14685"
      y2="10.164"
      stroke="currentColor"
    />
    <line
      x1="1.14693"
      y1="10.8359"
      x2="11.0464"
      y2="0.936406"
      stroke="currentColor"
    />
  </svg>
);

export default ArrowLeft;
