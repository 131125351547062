import noop from 'lodash/noop';
import { createContext, FC, useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookiesPolicy } from '.';

interface CookiesPolicyProps {
  isOpen: boolean;
  dismiss: () => void;
  accept: () => void;
}

const CookiesPolicyContext = createContext<CookiesPolicyProps>({
  isOpen: true,
  dismiss: noop,
  accept: noop,
});

const cookieKey = `vsco-cookies-policy`;

const CookiesPolicyProvider: FC = ({ children }) => {
  const [cookie, setCookie] = useCookies();
  const accepted = cookie[cookieKey];
  const [isOpen, setIsOpen] = useState<boolean>(!accepted);

  const dismiss = useCallback(() => {
    setIsOpen(false);
  }, []);

  const accept = useCallback(() => {
    setCookie(cookieKey, true);
    dismiss();
  }, [dismiss, setCookie]);

  return (
    <CookiesPolicyContext.Provider
      value={{
        isOpen,
        dismiss,
        accept,
      }}
    >
      {children}
      {isOpen && <CookiesPolicy />}
    </CookiesPolicyContext.Provider>
  );
};

export const useCookiesPolicy = (): CookiesPolicyProps =>
  useContext(CookiesPolicyContext);

export default CookiesPolicyProvider;
