import classNames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import find from 'lodash/find';
import map from 'lodash/map';
import { Nullable } from 'modules/global/types/types';
import { ConnectWallet } from 'modules/Wallet/components/ConnectWallet';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useAlgoSignerData from '../hooks/useAlgoSignerData';
import useMyAlgoData from '../hooks/useMyAlgoData';
import useWalletConnectData from '../hooks/useWalletConnectData';
import { Account, SignedTransaction } from '../types/Algorand';

export type AlgorandProviderContextType = {
  accounts?: Nullable<Account[]>;
  closeConnectSide: () => void;
  connect: (provider?: WalletProviders) => Promise<Record<string, never>>;
  connected?: boolean;
  disconnect: () => Promise<void>;
  error?: Nullable<string>;
  isAddressOwned: (address: string) => boolean;
  isAlgoSigner: () => boolean;
  isWalletConnect: () => boolean;
  openConnectSide: () => void;
  reset: () => void;
  signTxn: (group: any) => Promise<SignedTransaction[]>;
  walletProvider: WalletProviders;
};

export enum WalletProviders {
  AlgoSigner = 'algo-signer',
  WalletConnect = 'wallet-connect',
  MyAlgo = 'my-algo',
}

function serializeAccounts(accounts: Account[] | string[] | undefined) {
  return map(accounts, (account) =>
    typeof account === 'string' ? { address: account } : account,
  ) as Account[];
}

export const AlgorandProviderContext = createContext<any>({});

export const AlgorandProvider: FC = ({ children }) => {
  const [accounts, setAccounts] = useState<Account[]>();
  const [connected, setConnected] = useState(false);
  const [connectSidebarOpen, setConnectSidebarOpen] = useState(false);
  const [error, setError] = useState<Nullable<string>>();
  const [walletProvider, setWalletProvider] = useState<WalletProviders>();
  const sideBarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(sideBarRef, () => setConnectSidebarOpen(false));

  const {
    connected: wcConnected,
    connect: wcInit,
    accounts: wcAccounts,
    signTxn: wcSignTxn,
    disconnect: wcDisconnect,
  } = useWalletConnectData();
  const {
    connected: algoSignerConnected,
    connect: algoSignerInit,
    accounts: algoSignerAccounts,
    signTxn: algoSignerSignTxn,
    disconnect: algoSignerDisconnect,
  } = useAlgoSignerData();
  const {
    connected: myAlgoConnected,
    connect: myAlgoInit,
    accounts: myAlgoAccounts,
    signTxn: myAlgoSignTxn,
    disconnect: myAlgoDisconnect,
  } = useMyAlgoData();

  const reset = useCallback(() => {
    setAccounts(undefined);
    setError(undefined);
    setWalletProvider(undefined);
  }, [setError]);
  const openConnectSide = useCallback(() => setConnectSidebarOpen(true), []);
  const closeConnectSide = useCallback(() => setConnectSidebarOpen(false), []);

  const isAlgoSigner = useCallback(
    () => walletProvider === WalletProviders.AlgoSigner,
    [walletProvider],
  );
  const isWalletConnect = useCallback(
    () => walletProvider === WalletProviders.WalletConnect,
    [walletProvider],
  );
  const isMyAlgoConnect = useCallback(
    () => walletProvider === WalletProviders.MyAlgo,
    [walletProvider],
  );

  const connect = async (
    provider: WalletProviders = WalletProviders.WalletConnect,
  ) => {
    try {
      reset();
      setWalletProvider(provider);
      if (provider === WalletProviders.AlgoSigner) await algoSignerInit();
      if (provider === WalletProviders.WalletConnect) await wcInit();
      if (provider === WalletProviders.MyAlgo) await myAlgoInit();
    } catch (e: any) {
      const error = e as Error;
      reset();
      setError(error.message);
    }
  };

  const disconnect = useCallback(() => {
    reset();
    if (isAlgoSigner()) algoSignerDisconnect();
    if (isWalletConnect()) wcDisconnect();
    if (isMyAlgoConnect()) myAlgoDisconnect();
  }, [
    algoSignerDisconnect,
    isAlgoSigner,
    isMyAlgoConnect,
    isWalletConnect,
    myAlgoDisconnect,
    reset,
    wcDisconnect,
  ]);

  useEffect(() => {
    setConnected(wcConnected || algoSignerConnected || myAlgoConnected);
  }, [algoSignerConnected, myAlgoConnected, wcConnected]);

  useEffect(() => {
    if (isAlgoSigner()) setAccounts(serializeAccounts(algoSignerAccounts));
    if (isWalletConnect()) setAccounts(serializeAccounts(wcAccounts));
    if (isMyAlgoConnect()) setAccounts(serializeAccounts(myAlgoAccounts));
  }, [
    algoSignerAccounts,
    isAlgoSigner,
    isMyAlgoConnect,
    isWalletConnect,
    myAlgoAccounts,
    wcAccounts,
  ]);

  const signTxn = useCallback(
    (transactions) => {
      if (isAlgoSigner()) return algoSignerSignTxn(transactions);
      if (isWalletConnect()) return wcSignTxn(transactions);
      if (isMyAlgoConnect()) return myAlgoSignTxn(transactions);
    },
    [
      algoSignerSignTxn,
      isAlgoSigner,
      isMyAlgoConnect,
      isWalletConnect,
      myAlgoSignTxn,
      wcSignTxn,
    ],
  );

  const isAddressOwned = useCallback(
    (address: string) => connected && find(accounts, ['address', address]),
    [accounts, connected],
  );

  const contextValue = {
    accounts,
    closeConnectSide,
    connect,
    connected,
    disconnect,
    error,
    isAddressOwned,
    isAlgoSigner,
    isWalletConnect,
    openConnectSide,
    reset,
    signTxn,
    walletProvider,
  };

  return (
    <AlgorandProviderContext.Provider value={contextValue}>
      <div
        style={{
          pointerEvents: connectSidebarOpen ? 'none' : 'initial',
        }}
      >
        {children}
      </div>
      <div
        className={classNames(
          'fixed inset-y-0 z-50 xl:w-sidebar bg-sidebar transition-all',
          connectSidebarOpen ? 'right-0 w-full' : 'right-sidebar',
        )}
        ref={sideBarRef}
      >
        {connectSidebarOpen && (
          <ConnectWallet setConnectSidebarOpen={setConnectSidebarOpen} />
        )}
      </div>
    </AlgorandProviderContext.Provider>
  );
};

export const useAlgorand = (): AlgorandProviderContextType =>
  useContext(AlgorandProviderContext);

export default useAlgorand;
