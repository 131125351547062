/* eslint-disable no-console */
import { TRANSACTIONS_API_PATH, WALLET_API_PATH } from 'constants/apiPaths';
import useAxios from 'hooks/useAxios';
import { itemSerializer } from 'modules/Item/serializers/itemSerializer';
import { Item } from 'modules/Item/types/Item';
import { useEffect, useState } from 'react';
import { Transaction } from '../types/Transaction';

export const useTransactionDetails = ({
  publicWalletAddress,
  transactionId,
}: {
  publicWalletAddress: string;
  transactionId: string;
}): [Transaction, boolean, boolean] => {
  const [transaction, setTransaction] = useState<Transaction>();
  const { response, isLoading, isError } = useAxios<Transaction>(
    `${WALLET_API_PATH}/${publicWalletAddress}${TRANSACTIONS_API_PATH}/${transactionId}`,
  );

  useEffect(() => {
    if (response) {
      setTransaction({
        ...response,
        item: itemSerializer(response.item) as Item,
      });
    }
  }, [response]);

  return [transaction as Transaction, isLoading, isError];
};
