import { EXHIBITION_ROUTE } from 'constants/routes';
import { PendingPage } from 'pages/PendingPage';
import { ComponentType, FC, ReactElement, useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import useExhibitionDetails from '../hooks/useExhibitionDetails';
import { Exhibition, ExhibitionRouteParams } from '../types/Exhibition';

const withExhibitionCover =
  <P extends Record<string, any>>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { id } = useParams<ExhibitionRouteParams>();
    const [exhibition, error] = useExhibitionDetails({ id });
    const history = useHistory();

    useEffect(() => {
      if (exhibition && !exhibition?.isActive)
        history.replace(generatePath(EXHIBITION_ROUTE, { id }));
    }, [exhibition, history, id]);

    return exhibition?.isActive ? (
      <WrappedComponent exhibition={exhibition as Exhibition} {...props} />
    ) : (
      // TODO [NFT-74] Prepare generic error page
      <PendingPage error={error} />
    );
  };

export default withExhibitionCover;
