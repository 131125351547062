/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { TRANSACTION_ROUTE } from 'constants/routes';
import useHover from 'hooks/useHover';
import { Preview } from 'modules/Exhibition/components/Preview';
import { FilePreview } from 'modules/global/types/File';
import { Item } from 'modules/Item/types/Item';
import { Transaction } from 'modules/Transaction/types/Transaction';
import React, { FC, HTMLAttributes, useCallback, useMemo, useRef } from 'react';
import { generatePath, useHistory } from 'react-router';

// NFT-560 Force to use video preview if file is a video
function selectPreviewToDisplay(item: Item): FilePreview {
  const file = item.file;
  const preview = item.preview;
  return file.isVideo && preview.isImage ? file : preview;
}

interface TransactionItemProps extends HTMLAttributes<HTMLDivElement> {
  transaction: Required<Transaction>;
}

export const TransactionItem: FC<TransactionItemProps> = ({
  transaction,
  className,
  ...props
}) => {
  const history = useHistory();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const { item, id, publicWalletAddress } = transaction;
  const { seller, title } = item;
  const itemPreview = useMemo(() => selectPreviewToDisplay(item), [item]);

  const handleItemClick = useCallback(
    (params: Record<string, string | number>) =>
      history.push(generatePath(TRANSACTION_ROUTE, params)),
    [history],
  );
  return (
    <div
      ref={hoverRef}
      className={classNames(
        `flex flex-col text-xxs xl:text-base flex-center py-7 text-center cursor-pointer`,
        className,
      )}
      onClick={() =>
        handleItemClick({ publicWalletAddress, transactionId: id })
      }
      {...props}
    >
      <div className="flex-1 flex flex-center w-40 xl:w-52 mb-10 max-w-1/3 xl:max-w-full transition-all">
        <Preview preview={itemPreview} />
      </div>
      <div
        className={classNames(
          `flex flex-col flex-center gap-1 xl:gap-3 opacity-100 xl:opacity-0 transition-opacity`,
          { 'xl:opacity-100': isHover },
        )}
      >
        <span>{title}</span>
        <span className="font-medium">{seller.name}</span>
      </div>
    </div>
  );
};

export default TransactionItem;
