import classNames from 'classnames';
import { Button, ButtonProps } from 'components/Button/Button';
import { FileInfo } from 'components/FileInfo';
import ArrowDown from 'icons/ArrowDown';
import useLayoutConfig from 'layout/AppLayout/useLayoutConfig';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import { Preview } from 'modules/Exhibition/components/Preview';
import { usePhysicalCopy } from 'modules/Transaction/hooks/usePhysicalCopy';
import { Transaction } from 'modules/Transaction/types/Transaction';
import React, { FC } from 'react';
import { PhysicalCopyInput } from './components/PhysicalCopyInput';

interface TransactionDetailsProps {
  transaction: Transaction;
}

export const TransactionCoverPage: FC<TransactionDetailsProps> = ({
  transaction,
}) => {
  const { isAddressOwned } = useAlgorand();
  const buttonProps: ButtonProps = {
    className: 'bg-transparent px-14',
  };

  const { isSent, isError, isMobile, onEnterClick, onSaveEmail } =
    usePhysicalCopy({ transaction });

  useLayoutConfig({
    className: 'white-text-opened-menu',
  });

  return (
    <div className="text-white">
      <div className="h-screen flex-center">
        <div className="fixed inset-0 w-screen -z-1 flex-center bg-black">
          <Preview
            preview={transaction.item.preview}
            style={{ opacity: 0.9, maxWidth: 'none' }}
            className="h-full md:h-auto md:w-full"
          />
        </div>
        <div>
          {transaction.item.isPhysicalCopy && (
            <PhysicalCopyInput
              onSave={onSaveEmail}
              onDismiss={onEnterClick}
              isSent={isSent}
              isError={isError}
            />
          )}
          <div className="m-5 mt-16 md:absolute left-0 bottom-0">
            <FileInfo
              name={transaction.item.title}
              author={transaction.item.seller?.name}
              center={isMobile}
            />
          </div>
        </div>
      </div>
      <div className="fixed bottom-5 inset-x-0 z-20 ">
        <div
          className={classNames(
            'flex justify-center items-center max-w-md mx-auto transition-opacity opacity-100',
          )}
        >
          {isAddressOwned(transaction?.publicWalletAddress) && (
            <Button
              onClick={onEnterClick}
              {...buttonProps}
              style={{ maxHeight: 50 }}
            >
              <ArrowDown />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionCoverPage;
