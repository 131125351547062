import { Button } from 'components/Button';
import VSCOFaceSad from 'icons/VSCOFaceSad';
import useAlgorand from 'modules/Algorand/providers/AlgorandProvider';
import { useFaqModal } from 'modules/Faq/providers/FaqModalProvider';
import React, { FC, HTMLAttributes, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FailedProps extends HTMLAttributes<HTMLDivElement> {}

export const Failed: FC<FailedProps> = () => {
  const { error, reset: handleTryAgainClick } = useAlgorand();
  const { open: openFaqModal } = useFaqModal();

  const goToFaq = useCallback(() => openFaqModal(), [openFaqModal]);

  return (
    <>
      <VSCOFaceSad />
      <div className="flex flex-col">
        <span>
          Error connecting your wallets. {error?.replace(/\[.*\] /gm, '') + ' '}
        </span>
        <span
          className="underline cursor-pointer"
          onClick={handleTryAgainClick}
        >
          Try again.
        </span>
      </div>
      <Button onClick={goToFaq} upperCase>
        Help
      </Button>
    </>
  );
};

export default Failed;
